@use 'sass:math';
@use './variables';

@mixin button-reset {
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: none;
  overflow: visible;
  padding: 0;
  width: auto;
}

@mixin collapse-transition($time) {
  height: 100%;
  transition: height $time;
}

@mixin arrow-down($color, $height) {
  border-left: $height solid transparent;
  border-right: $height solid transparent;
  border-top: $height solid $color;
  height: 0;
  width: 0;
}

@mixin form-row($col-count) {
  $column-margin-right: 16px;
  display: flex;
  flex-basis: 100%;
  margin-bottom: $column-margin-right;

  &:last-child {
    margin-bottom: 0;
  }

  > * {
    @if ($col-count > 1) {
      width: calc(
        100% / #{$col-count} - #{math.div($column-margin-right, $col-count)}
      );
    } @else {
      width: 100%;
    }
  }

  > :not(:first-child) {
    margin-left: $column-margin-right;
  }
}

@mixin bonus-sum {
  background-color: variables.$color-grey-200;
  border-radius: 4px;
  color: variables.$color-grey-500;
  display: inline-block;
  padding: 0 8px;
  white-space: nowrap;

  > svg {
    fill: variables.$color-grey-500;
    margin-left: 8px;
  }
}

@mixin field-touched {
  color: variables.$color-black !important;
}

@mixin field-error {
  background-color: variables.$color-red-100;
  border: 1px solid variables.$color-red-500;
}

@mixin buttons-row($margin: variables.$margin-common) {
  display: flex;

  > *:not(:last-child) {
    margin-right: $margin;
  }
}

@mixin media($breakpoint) {
  @if $breakpoint==large {
    @media only screen and (max-width: 992px) {
      @content;
    }
  }
  @if $breakpoint==medium {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
  @if $breakpoint==small {
    @media only screen and (max-width: 576px) {
      @content;
    }
  }
}

@mixin set-heights-based-on-lines(
  $line-height,
  $padding,
  $max-lines,
  $min-lines
) {
  $vertical-total-padding: 2 * $padding + 2px;

  max-height: $max-lines * $line-height + $vertical-total-padding;
  min-height: $min-lines * $line-height + $vertical-total-padding;
}

@mixin input {
  background-color: variables.$color-white;
  border: 1px variables.$color-grey-300 solid;
  border-radius: 4px;
  box-sizing: border-box;
  color: variables.$color-black;
  font-family: variables.$font-family;
  font-size: variables.$font-size-small;
  height: 40px;
  outline: none;
  padding: 0 variables.$margin-small;
  width: 100%;

  &:focus:not(.error) {
    background-color: variables.$color-blue-100;
    border: 1px variables.$color-blue-500 solid;
    outline: none;
  }

  &:disabled {
    background-color: variables.$color-grey-100;
    color: variables.$color-grey-800;
  }

  &::placeholder {
    color: variables.$color-grey-500;
  }

  // remove arrows from number input
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    appearance: textfield;
  }

  &.default {
    height: 40px;
  }

  &.small {
    height: 32px;
  }

  &.error {
    @include field-error;
  }
}

@mixin dropdown-wrapper {
  background-color: variables.$color-white;
  border: 1px variables.$color-blue-500 solid;
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: normal;
  max-height: 241px;
  overflow-y: auto;
  padding: 8px;
  position: absolute;
  top: calc(#{variables.$dropdown-height} + 2px);
  width: 100%;
  z-index: 100;
}

@mixin dropdown-option {
  border-radius: inherit;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  padding: 8px;
  white-space: nowrap;

  &:not(.selectedOption):not(.checkboxOption):hover {
    background-color: variables.$color-blue-100;
  }
}

@mixin switch-size($width, $height, $switcher-width, $switcher-right) {
  width: $width;

  .inner {
    &::after,
    &::before {
      content: '';
      height: $height;
    }
  }

  .switcher {
    margin: 2px;
    right: $switcher-right;
    width: $switcher-width;
  }
}

@mixin pressed-tariff-block {
  &:active {
    border-color: variables.$color-grey-200;

    button [class*='checkboxControl'] {
      border-color: variables.$color-grey-200;
    }
  }
}
