@use 'src/styles/variables';
@use 'src/styles/mixins';

@include mixins.media(small) {
  .mainLayout {
    margin-left: 0;
    padding: variables.$main-layout-small-padding;
    // сделал 100% потому что меню слева пропадает
    width: 100%;
  }
}
