@use 'src/styles/mixins';

@include mixins.media(large) {
  .columns {
    display: flex;
    flex-wrap: wrap;

    > div {
      min-width: 350px;
      width: 100%;
    }

    > div:not(:last-child) {
      margin-right: 0;
    }
  }
}
