@use 'src/styles/variables';

.container {
  border: 1px solid variables.$color-grey-200;
  border-radius: variables.$border-radius;
  display: grid;
  grid-template-columns: auto 1fr;
  padding: variables.$margin-common;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.name {
  font-size: variables.$font-size-common;
  font-weight: 500;
  line-height: 16px;
}

.note {
  font-size: variables.$font-size-small;
  line-height: 16px;
  padding-right: 32px;
}

.statusOrCheckboxContainer {
  padding: 4px 12px 4px 4px;
}

.content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  row-gap: variables.$margin-small;
}
