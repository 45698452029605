@use 'src/styles/variables';
@use 'src/styles/mixins';

.option {
  @include mixins.dropdown-option;
}

.selectedOption {
  background-color: variables.$color-blue-500;
  color: variables.$color-white;

  .labelRight {
    color: variables.$color-white;
  }
}

.checkboxOption {
  align-items: center;
  display: flex;
  height: 20px;
  line-height: 14px;

  &:not(.selectedOption):hover .checkboxControl:not(.checkboxControlChecked) {
    background-color: variables.$color-blue-100;
    border-color: variables.$color-blue-500;
  }
}

.labelRight {
  color: variables.$color-grey-600;
  margin-left: auto;
}
