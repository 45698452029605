@use 'src/styles/variables';

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  line-height: 24px;
  margin-bottom: variables.$margin-small;
}

.title {
  font-size: variables.$font-size-h2;
  font-weight: 500;
}

.fieldName {
  margin-bottom: variables.$margin-small;
}
