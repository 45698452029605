@use 'src/styles/variables';
@use 'src/styles/mixins';

$header-padding: variables.$main-layout-padding;

@mixin marker {
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  color: variables.$color-white;
  font-weight: bold;
  line-height: 16px;
  min-width: 60px;
  padding: 4px 8px;
  text-align: center;
}

@mixin colored-markers {
  .rank {
    background-color: variables.$color-orange-600;
  }

  .deleted {
    background-color: variables.$color-red-600;
  }

  .twoFactorEnable {
    background-color: variables.$color-pink-500;
  }

  .twoFactorDisable {
    background-color: variables.$color-blue-grey-400;
  }

  .signInRestriction {
    background-color: variables.$color-red-600;
  }

  .active {
    background-color: variables.$color-green-600;
  }

  .trustedPlayer {
    background-color: variables.$color-blue-600;
  }

  .unTrustedPlayer {
    background-color: variables.$color-red-600;
  }

  .internalOutgoingMoneyTransfersEnabled {
    background-color: variables.$color-purple-500;
  }

  .internalOutgoingMoneyTransfersDisabled {
    background-color: variables.$color-grey-400;
  }
}

.header {
  display: flex;
  font-weight: bold;
  line-height: 24px;
  margin-left: -$header-padding;
  margin-top: -$header-padding;
  padding: 20px $header-padding;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;

  &.fixed {
    margin-top: 0;
    position: fixed;
  }
}

.fakeHeader {
  margin-top: -$header-padding;
}

.header:hover {
  @include colored-markers;
}

.rankArrow {
  color: variables.$color-white;
}

.markersUncoloredWrapper {
  margin-left: variables.$margin-small;
  opacity: 0;
  transition: opacity 170ms ease-in;

  .marker {
    background-color: variables.$color-grey-300;
  }
}

.markersWrapper {
  margin-bottom: variables.$margin-large;
}

.marker {
  @include marker;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  transition: background-color 150ms;

  &.disabled {
    cursor: initial;
  }
}

.nameContainer {
  align-items: center;
  display: flex;

  .name {
    font-size: 20px;
    margin-right: variables.$margin-common;
  }
}

.nameContainer:hover {
  .editBtn {
    opacity: 1;
  }
}

.playerId {
  font-weight: normal;
}

.editBtn {
  opacity: 0;
  transition: opacity ease 0.4s;
}

.clickable {
  cursor: pointer;
}

@include colored-markers;

.note {
  margin-bottom: variables.$margin-large;
}
