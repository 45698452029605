@use 'src/styles/variables';

.loaderContainer {
  align-items: center;
  color: variables.$color-grey-600;
  display: flex;
  font-size: variables.$font-size-small;
  justify-content: center;
  line-height: 16px;

  .loader {
    margin-right: variables.$margin-small;
  }
}
