@use 'src/styles/variables';

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  width: 100vw;
}

.container {
  background: variables.$color-white;
  border: 1px solid variables.$color-grey-200;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 400px;
}

.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: variables.$margin-common;
  max-width: 400px;
  text-align: center;
}

.button {
  width: 100%;
}

.qrCode {
  height: 200px;
}

.qrCodeWrapper {
  display: flex;
  height: 200px;
  justify-content: center;
  margin: 24px 0;
  width: 200px;
}

.secret {
  cursor: pointer;
}

.loader {
  align-items: center;
}
