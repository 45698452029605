@use 'src/styles/variables';

.checkbox {
  // hack to compensate for the distance between label and checkbox, since we pass label as an empty string and do not formally use it
  margin-right: -4px;
}

.iconContainer svg {
  height: 16px;
}
