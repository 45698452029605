@use 'src/styles/variables';

.questionIcon {
  align-items: center;
  color: variables.$color-grey-500;
  cursor: pointer;
  display: flex;
  height: 16px;
  justify-content: center;
  width: 16px;
}
