@use 'src/styles/variables';

$main-layout-width: calc(100% - #{variables.$navigation-width});

.layout {
  background-color: variables.$color-grey-100;
  display: flex;
  min-height: 100vh;
}

.mainLayout {
  box-sizing: border-box;
  margin-left: variables.$navigation-width;
  padding: variables.$main-layout-padding;
  position: relative;
  // раньше было просто 100% что не логично. При этом появлялся скролл при ресайзе страницы старницы игрока с открытой
  // таблицей в каком либо блоке
  width: $main-layout-width;
}
