@use 'src/styles/variables';

.restriction {
  align-items: center;
  background-color: variables.$color-white;
  border: 1px solid variables.$color-grey-200;
  border-radius: variables.$border-radius;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: variables.$font-size-small;
  font-weight: 400;
  line-height: 16px;
  margin-right: variables.$margin-small;
  padding: 12px variables.$margin-common;

  &:last-child {
    margin-right: 0;
  }

  &.orange {
    border-color: variables.$color-orange-500;
  }

  &.red {
    border-color: variables.$color-red-500;
  }

  &.disabled {
    cursor: default;
  }
}

.icon {
  margin-right: variables.$margin-small;
}

.status {
  color: variables.$color-grey-600;
}
