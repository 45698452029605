@use 'src/styles/variables';

.iconContainer {
  align-items: center;

  svg {
    height: 14px;
  }
}

.iconContainer:hover {
  svg {
    color: variables.$color-blue-500;
  }
}

.iconContainer:active {
  svg {
    color: variables.$color-blue-600;
  }
}
