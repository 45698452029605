@use 'src/styles/variables';

.wrapper {
  border: 1px solid variables.$color-red-500;
  border-radius: variables.$border-radius-small;
  font-size: variables.$font-size-small;
  line-height: 16px;
  margin-bottom: 24px;
  padding: 16px;
}

.errorsWrapper:not(:last-of-type) {
  margin-bottom: 12px;
}

.header {
  color: variables.$color-red-500;
  font-size: variables.$font-size-common;
  font-weight: 500;
}

.text {
  color: variables.$color-black;
  font-size: variables.$font-size-small;
  margin-top: variables.$margin-extra-small;
}
