@use 'src/styles/variables';

.lightboxSidebar {
  background: variables.$color-white;
  flex-shrink: 0;
  height: 100%;
  overflow: hidden;
  position: absolute;
  right: -#{variables.$document-sidebar-width};
  width: variables.$document-sidebar-width;
}
