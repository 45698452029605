@use 'src/styles/variables';

.subHeader {
  color: variables.$color-grey-800;
  column-gap: 12px;
  display: flex;
  font-size: variables.$font-size-common;
  font-weight: 400;
  line-height: 16px;
}

.title {
  color: variables.$color-grey-600;
  margin-right: variables.$margin-extra-small;
}

.affiliateNameWrapper {
  display: flex;
}
