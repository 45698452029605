@use 'src/styles/variables';

.content {
  margin-bottom: variables.$margin-large;
}

.icon {
  margin: 0 variables.$margin-small;
}

.radioCheckbox {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: variables.$font-size-small;
  gap: 9px;

  &.disabled {
    color: variables.$color-grey-600;
    cursor: default;
  }
}

.titleCheckbox {
  margin-bottom: variables.$margin-common;
}

.datePicker {
  width: 190px;
}
