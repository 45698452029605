@use 'src/styles/variables';

.container {
  width: 392px;
}

.confirmationInput {
  margin-bottom: 0;
  margin-right: variables.$margin-small;
}

.inputContainer {
  width: 100%;
}

.label {
  font-size: 12px;
  margin-bottom: variables.$margin-small;
}

.confirmationContainer {
  align-items: flex-end;
  display: flex;
}
