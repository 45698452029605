@use 'src/styles/variables';
$transition-duration: 200ms;

.sidebarWrapper {
  background-color: variables.$color-white;
  box-shadow: 1px 0 0 #edeef2;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  transition: all $transition-duration;
  width: variables.$navigation-width;
  z-index: 5;

  &:hover {
    box-shadow:
      0 1px 0 variables.$color-grey-200,
      4px 0 4px rgba(191, 191, 191, 0.16);
    width: variables.$navigation-hover-width;
  }
}

.sidebarWrapper:hover .subNavigation {
  display: block;
}

.subNavigation {
  display: none;
}

.sidebar {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  padding: variables.$vertical-sidebar-padding
    variables.$horizontal-sidebar-padding;
}
