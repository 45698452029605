@use 'src/styles/variables';

.root {
  display: flex;
  flex-direction: column;
}

.container {
  & + & {
    margin-top: variables.$margin-large;
  }
}

.content {
  margin-bottom: variables.$margin-common;
  margin-top: variables.$margin-small;
}

.nextCashback {
  font-size: variables.$font-size-h2;
  font-weight: 500;
  line-height: 24px;
}

.list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.listItem {
  align-items: center;
  display: flex;
  font-size: variables.$font-size-small;
  height: 32px;
  justify-content: space-between;
  line-height: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid variables.$color-grey-100;
  }
}

.listItemTitle {
  color: variables.$color-grey-600;
}

.button {
  margin-top: variables.$margin-small;
}
