@use 'src/styles/variables';
@use 'src/styles/mixins';

.checkbox {
  align-items: center;
  background-color: inherit;
  border: 0;
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 0;

  &.small {
    font-size: 13px;
    line-height: 13px;
  }

  &.default {
    font-size: 14px;
    line-height: 14px;
  }

  &:disabled {
    color: variables.$color-grey-600;
    cursor: not-allowed;
  }
}

.checkbox:disabled .checkboxControl {
  background-color: variables.$color-grey-100;
  border: 1px solid variables.$color-grey-300;
}

.checkboxControl {
  align-items: center;
  background-color: variables.$color-white;
  border: 1px solid variables.$color-grey-300;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin-right: 8px;
  transition: border-color 0.1s ease;
  z-index: 0;

  &.small {
    height: 16px;
    width: 16px;
  }

  &.default {
    height: 24px;
    width: 24px;
  }

  &:hover {
    border-color: variables.$color-blue-500;
  }
}

.checked {
  background-color: variables.$color-blue-500;
  border: 1px solid variables.$color-blue-500;
  color: variables.$color-white;
}

.label {
  font-size: variables.$font-size-small;
  font-weight: 500;
  line-height: 1;
}

.importantTypeLabel {
  color: variables.$color-red-500;
}
