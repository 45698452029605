@use 'src/styles/variables';

.container {
  column-gap: 4px;
  display: flex;
}

.from {
  color: variables.$color-grey-600;
  text-decoration: line-through;
}

.arrowContainer {
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
  width: 16px;
}

.arrow {
  margin-bottom: 1px;
}

.creditArrow {
  color: variables.$color-red-500;
}

.debitArrow {
  color: variables.$color-green-600;
}
