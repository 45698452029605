@use 'src/styles/variables';

.descWrapper {
  border: 1px solid variables.$color-orange-500;
  border-radius: variables.$border-radius;
  box-sizing: border-box;
  font-size: variables.$font-size-small;
  line-height: 16px;
  margin-bottom: variables.$margin-large;
  padding: variables.$margin-common;
}

.descTitle {
  font-size: variables.$font-size-large;
  font-weight: 700;
  margin-bottom: 12px;
}

.descSubTitle {
  color: variables.$color-orange-600;
  font-size: variables.$font-size-common;
  margin-bottom: variables.$margin-small;
}

.descContent {
  font-size: variables.$font-size-small;
  font-weight: 400;
  line-height: variables.$font-size-h2;
}

.checkboxWrapper {
  margin: variables.$margin-common 0 0;
}
