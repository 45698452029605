.container {
  display: flex;
  height: 18px;
  line-height: 18px;
  white-space: nowrap;
}

.textStartPart,
.textEndPart {
  height: inherit;
  overflow: hidden;
}

.textStartPart {
  white-space: break-spaces;
  word-break: break-all;
}

.textEndPart {
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;

  &::after {
    content: '\200E';
  }
}
