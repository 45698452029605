.currencyDropdownContainer {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: space-between;
  padding: 4px 8px;
  width: 72px;

  > span {
    font-weight: 400;
  }

  > div {
    right: 10px;
    top: 10px;
  }
}

.currencyDropdownContainer + div {
  top: 28px;
}

.currencyDropdownWrapper [class*='dropdownWrapper'] {
  min-height: auto;
}
