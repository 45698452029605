@use 'src/styles/variables';

.wrapper {
  background-color: variables.$color-white;
  border: 1px solid variables.$color-grey-200;
  border-radius: variables.$border-radius;
  line-height: 20px;
  padding: variables.$margin-small;
}

.wrapper:hover {
  [data-testid='editButton'] {
    opacity: 1;
  }
}
