@use 'src/styles/variables';
@use 'src/styles/mixins';

.cardIcon {
  left: -30px;
  position: absolute;
  top: 3px;
}

.headerContentContainer {
  display: flex;
  position: relative;
  white-space: pre-line;
  word-break: break-word;
}

.headerContent {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}

.headerColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.headerRightColumn {
  align-items: flex-end;
}

.header {
  font-weight: bold;
}

.leftSubHeader {
  color: variables.$color-grey-500;
  font-size: variables.$font-size-small;
}

.rightSubHeader {
  font-size: variables.$font-size-small;
}
