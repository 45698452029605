@use 'src/styles/variables';

.container {
  margin-top: variables.$margin-large;
}

.content {
  margin-bottom: variables.$margin-common;
}

.blockTitle {
  display: flex;
  font-size: variables.$font-size-common;
  font-weight: 500;
  margin-bottom: variables.$margin-common;
}
