@use 'src/styles/variables';
@use 'src/styles/mixins';

.selectedFilter {
  align-items: center;
  display: flex;
  position: relative;

  &.isOpened {
    margin-right: 150px;
  }
}

.selectedFilterList {
  left: 100%;
}

.list {
  border: 1px solid variables.$color-blue-500;
  border-radius: variables.$margin-small;
  box-shadow: 0 2px 4px 0 variables.$color-black-16;
  left: 0;
  min-width: 224px;
  padding: variables.$margin-common;
  position: absolute;
  top: 52px;
}

.listItem {
  border-radius: variables.$border-radius-small;
  cursor: pointer;
  padding: variables.$margin-small;
  transition: background-color linear 300ms;

  &:hover {
    background-color: variables.$color-blue-100;
  }
}
