@use 'src/styles/variables';
@use 'src/styles/mixins';

.searchInput {
  @include mixins.input;
  border-color: variables.$color-grey-200;
  border-radius: variables.$border-radius;
  font-size: variables.$font-size-common;
  height: 56px;
  padding: 0 32px 0 48px;
}

.searchWrapper {
  color: variables.$color-grey-500;
  position: relative;
}

.searchIcon {
  color: currentColor;
  fill: currentColor;
  height: 14px;
  left: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  z-index: 1;
}

.searchWrapperNotEmpty {
  color: variables.$color-black;
}
