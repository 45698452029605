@use 'src/styles/variables';
@use 'src/styles/mixins';

.tabNamesWrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
}

.tabName {
  @include mixins.button-reset;
  color: variables.$color-grey-600;
  font-size: variables.$font-size-common;
  font-weight: 500;
  line-height: 16px;

  &:not(:last-child) {
    margin-right: variables.$margin-common;
  }

  &:hover {
    color: variables.$color-blue-500;
  }
}

.tabNameSelected {
  color: variables.$color-black;
}
