@use 'src/styles/variables';

.container {
  border: 1px solid variables.$color-grey-200;
  border-radius: variables.$border-radius;
  display: flex;
  font-size: variables.$font-size-common;
  font-weight: 500;
  padding: variables.$margin-common;
}
