@use 'src/styles/variables';

$border-size: 1px;
$border-row: $border-size solid transparent;

.table {
  background-color: variables.$color-white;
  border: variables.$border-table-width solid transparent;
  border-bottom: 0;
  border-collapse: initial;
  border-radius: variables.$border-radius;
  box-sizing: border-box;
  width: 100%;

  &.fullScreenTable {
    border: variables.$border-full-screen-table-width solid transparent;
    border-bottom: 0;
  }

  &.fullScreenTable .stickyColumn {
    left: variables.$border-full-screen-table-width;
    z-index: 1;
  }

  &.fullScreenTable .stickyColumn::before,
  &.fullScreenTable .th.stickyColumn::before {
    width: variables.$border-full-screen-table-width;
  }
}

.rightBorder {
  position: relative;

  &::after {
    background-color: variables.$color-black-5;
    content: '';
    height: calc(100% + $border-size * 2);
    position: absolute;
    right: 0;
    top: -$border-size;
    width: $border-size;
  }
}

.stickyColumn {
  background-color: variables.$color-white;
  left: variables.$border-table-width;
  position: sticky;

  &::before {
    background-color: variables.$color-white;
    content: '';
    height: calc(100% + $border-size * 2);
    left: -$border-size;
    position: absolute;
    top: -$border-size;
    transform: translateX(-100%);
    width: variables.$border-table-width;
  }
}

.tr {
  font-size: variables.$font-size-small;
  min-height: 40px;

  &.clickable {
    cursor: pointer;
  }
}

.tr.clickable:hover .td {
  border-color: variables.$color-blue-500;

  &.rightBorder::after {
    height: 100%;
    top: 0;
  }
}

.tr.active .td {
  background-color: variables.$color-blue-100;
  border-color: variables.$color-blue-500;
}

.table:not(.hasSummaryRow):not(.hasTotalRow) .tr:nth-child(even) {
  .td {
    background-color: variables.$color-grey-100;
  }

  &.active .td {
    background-color: variables.$color-blue-100;
  }
}

.table.hasSummaryRow .tr:nth-child(odd),
.table.hasTotalRow .tr:nth-child(odd) {
  .td {
    background-color: variables.$color-grey-100;
  }

  &.active .td {
    background-color: variables.$color-blue-100;
  }
}

.cell,
.th {
  box-sizing: border-box;
  padding: variables.$table-cell-padding;

  &.alignLeft {
    text-align: left;
  }

  &.alignRight {
    text-align: right;
  }

  &.alignCenter {
    text-align: center;
  }
}

.cell {
  border-bottom: $border-row;
  border-top: $border-row;
  height: 40px;
  transition: border-color linear 0.1s;
  vertical-align: top;

  &:first-child {
    border-left: $border-row;
    border-radius: variables.$border-radius-small 0 0
      variables.$border-radius-small;
  }

  &:last-child {
    border-radius: 0 variables.$border-radius-small
      variables.$border-radius-small 0;
    border-right: $border-row;
  }
}

.th {
  color: variables.$color-grey-600;
  font-size: variables.$font-size-small;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;

  &.stickyColumn::before {
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: variables.$border-table-width;
  }
}

.summaryRow {
  background-color: variables.$color-blue-100;
  font-size: variables.$font-size-small;
}

.hasTotalRow .tr:first-child .td.cell {
  &,
  &.stickyColumn {
    background-color: variables.$color-blue-100;
  }
}
