@use 'src/styles/variables';
@use 'src/styles/mixins';

.status {
  align-items: center;
  color: variables.$color-grey-600;
  display: flex;
  font-size: variables.$font-size-small;
  justify-content: center;
  left: 0;
  position: sticky;

  .loader {
    margin-right: variables.$margin-small;
  }

  &.error {
    color: variables.$color-red-500;
  }
}

.errorMessageContainer {
  align-items: center;
  display: flex;
  line-height: 16px;
}

.updateButton {
  margin-left: 10px;
}

.errorIcon {
  font-size: 14px;
  margin-right: variables.$margin-extra-small;
  padding: 1px;
}
