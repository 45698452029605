@use 'src/styles/variables';

.groupHeader {
  align-items: center;
  border-radius: variables.$border-radius-small;
  color: variables.$color-black;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding: variables.$margin-small;
  white-space: nowrap;

  &:hover {
    background-color: variables.$color-grey-100;
  }
}

.groupHeader.active {
  background-color: variables.$color-blue-500;
  color: variables.$color-white;

  .iconContainer {
    color: variables.$color-white;
  }

  .dropdownIcon {
    color: variables.$color-white;
  }
}

.iconContainer {
  color: variables.$color-grey-400;
  display: inline-block;
  margin-right: variables.$margin-small;
  min-width: 24px;
  position: relative;
  text-align: center;
}

.groupHeaderLeftSide {
  display: flex;
}

.dropdownIcon {
  color: variables.$color-grey-400;
  height: 16px;
  margin-right: 0;
  min-width: 9px;
  padding: 4px;
  transition: all ease 0.1s;
}

.groupHeader .dropdownIcon {
  color: variables.$color-grey-400;
}

.groupHeader.isOpen .dropdownIcon {
  transform: rotate(180deg);
}
