@use 'src/styles/variables';

.datePickerWithRange {
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 5;
}

.datePickerContainer {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}
