@use 'src/styles/variables';
@use 'src/styles/mixins';

.container {
  border: 1px solid variables.$color-grey-200;
  border-radius: variables.$border-radius;
  display: grid;
  grid-template-columns: auto 1fr;
  line-height: 16px;
  padding: variables.$margin-common;
  transition: all ease 0.1s;

  &:not(:last-child) {
    margin-bottom: variables.$margin-small;
  }

  &:hover {
    border-color: variables.$color-grey-400;
    cursor: pointer;
  }

  @include mixins.pressed-tariff-block;
}

.container:hover :global([class*='checkboxControl']) {
  border-color: variables.$color-blue-500;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.tariffName {
  font-weight: 500;
}

.tariffType {
  font-size: variables.$font-size-small;
}

.content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: variables.$margin-extra-small;
  row-gap: variables.$margin-small;
}

.tariffId {
  font-weight: 500;
}

.checkbox {
  pointer-events: none;
}
