@use 'src/styles/variables';

.cashbackBlock {
  align-items: center;
  background-color: variables.$color-white;
  border-radius: variables.$border-radius;
  cursor: pointer;
  display: flex;
  font-size: variables.$font-size-small;
  line-height: 16px;
  padding: variables.$margin-common;
}

.iconContainer {
  svg {
    height: 20px;
    width: 20px;
  }
}

.title {
  margin-left: 10px;
  margin-right: variables.$margin-extra-small;
}

.sum {
  margin-right: variables.$margin-extra-small;
}

.more {
  color: variables.$color-grey-800;
}
