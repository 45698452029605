@use 'src/styles/variables';

.list {
  background-color: variables.$color-white;
  border: 1px solid variables.$color-blue-500;
  border-radius: variables.$border-radius;
  box-shadow: 0 2px 4px 0 variables.$color-black-16;
  left: calc(100% - #{variables.$margin-small});
  max-height: 241px;
  min-width: 224px;
  overflow-y: auto;
  padding: variables.$margin-common;
  position: absolute;
  top: 52px;
  z-index: 2;
}

.emptyText {
  color: variables.$color-grey-600;
}

.listItem {
  border-radius: variables.$border-radius-small;
  cursor: pointer;
  padding: variables.$margin-small;
  transition: background-color linear 300ms;

  &:hover {
    background-color: variables.$color-blue-100;
  }
}
