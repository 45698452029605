@use 'src/styles/variables';

.listLoaderContainer {
  justify-content: flex-start;
}

.container {
  align-items: center;
  border: 1px solid variables.$color-grey-200;
  border-radius: variables.$border-radius;
  column-gap: variables.$margin-small;
  display: flex;
  padding: variables.$margin-common;

  &.clickable {
    cursor: pointer;
  }
}

.icon {
  color: variables.$color-blue-grey-500;
  height: 18px;
  padding: 0 variables.$margin-extra-small;
}

.message {
  font-size: variables.$font-size-small;
  line-height: 16px;
}
