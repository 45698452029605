@use 'src/styles/variables';
@use 'src/styles/mixins';

.viewNoteWrapper {
  align-items: flex-start;
  display: flex;
  margin: variables.$margin-small + 1px;
  position: relative;
}

.emptyNote {
  color: variables.$color-grey-500;
}

.text {
  align-self: center;
  overflow-wrap: anywhere;
  white-space: pre-line;
}

.actionButton {
  opacity: 0;
  position: absolute;
  right: 0;
  top: -2px;
  transition: opacity ease 0.4s;
}
