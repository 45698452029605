@use 'src/styles/variables';

.container {
  align-items: flex-start;
  column-gap: variables.$margin-small;
  display: flex;
}

.copyButton {
  padding-top: 2px;
}
