@use 'src/styles/variables';
@use 'src/styles/mixins';

.button {
  @include mixins.button-reset;

  align-items: center;
  column-gap: variables.$margin-extra-small;
  display: flex;
}

.button:hover {
  cursor: pointer;

  .iconContainer svg {
    color: variables.$color-blue-500;
  }
}
