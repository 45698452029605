@use 'src/styles/variables';
@use 'src/styles/mixins';

$left-table-padding-small: calc(
  #{variables.$main-layout-small-padding} +
    #{variables.$border-full-screen-table-width}
);

@include mixins.media(small) {
  .stickyHeader {
    padding-left: $left-table-padding-small;
  }
}
