@use 'src/styles/variables';

.extendedHeader {
  + * {
    margin-top: variables.$margin-small;
  }
}

.actionsContainer {
  display: flex;
  gap: 16px;
}

.filterContainer {
  margin-bottom: variables.$margin-small;
}
