@use 'src/styles/variables';

.container {
  align-items: center;
  display: flex;
  font-size: variables.$font-size-small;
  line-height: 16px;
}

.title {
  margin-right: variables.$margin-extra-small;
}

.cardIcon {
  margin-right: 10px;

  svg {
    height: 16px;
  }
}
