@use 'src/styles/variables';

.tooltip {
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  gap: 10px;
  line-height: 16px;
  padding: 8px;
  width: max-content;
  z-index: 10;

  &.dark {
    background-color: variables.$color-black;
    box-shadow: 0 2px 4px rgba(variables.$color-black, 0.04);
    color: variables.$color-grey-100;
    opacity: 0.8;
  }

  &.light {
    background-color: variables.$color-white;
    box-shadow:
      0 4px 32px 0 rgba(variables.$color-black, 0.16),
      0 0 4px 0 rgba(variables.$color-black, 0.04);
  }
}

.tooltip.light {
  .tooltipArrow {
    fill: variables.$color-white;
  }
}

.closeIconContainer {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 16px;
  justify-content: center;
  width: 16px;

  :hover {
    color: variables.$color-white;
  }
}

.closeIcon {
  color: variables.$color-grey-800;
  font-size: 14px;
}

.button {
  margin-top: 8px;
}
