@use 'src/styles/variables';
@use 'src/styles/mixins';

.form {
  display: flex;
  justify-content: space-between;
}

.buttons {
  display: flex;
  flex-direction: column;
  margin-right: variables.$margin-small;
  position: relative;
  top: 8px;
}

.actionButton {
  margin-bottom: 2px;
}

.input {
  $line-height: 20px;
  @include mixins.set-heights-based-on-lines(
    $line-height,
    variables.$margin-small,
    8,
    3
  );
  font-size: variables.$font-size-common;
  line-height: $line-height;
  padding: variables.$margin-small;
}

.inputWrapper {
  margin-bottom: 0;
  margin-right: variables.$margin-small;
  width: 100%;
}
