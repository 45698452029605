@use 'src/styles/variables';
@use 'src/styles/mixins';

.inputContainer {
  position: relative;
}

.inputSum {
  @include mixins.input;

  &.withCurrency {
    padding-right: 38px;
  }
}

.currency {
  align-items: center;
  bottom: 0;
  color: variables.$color-grey-600;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 100%;
  position: absolute;
  right: 8px;
}
