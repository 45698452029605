@use 'src/styles/variables';
@use 'src/styles/mixins';

.datePickerContainer {
  background-color: variables.$color-white;
  border: 1px solid variables.$color-blue-500;
  border-radius: 8px;
  display: flex;
  overflow: hidden;
}

.datePicker {
  border-left: 1px solid variables.$color-grey-300;
}

.dateTitle {
  align-items: center;
  border-bottom: 1px solid variables.$color-grey-300;
  display: flex;
  min-height: 49px;
  padding-left: 12px;
}

.rangesContainer {
  display: flex;
  flex-direction: column;
  min-width: 224px;
  padding: 16px 12px;
}

.range {
  padding: 12px;

  &.active {
    color: variables.$color-blue-600;
    cursor: default;
  }
}
