@use 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  border-bottom: 1px solid variables.$color-grey-200;
  display: flex;
  padding: variables.$margin-common;
}

.content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 24px 16px 16px;
}

.note {
  margin-bottom: auto;
  width: 100%;
}

.documentIcon {
  color: variables.$color-blue-grey-500;
  height: 18px;
  margin-right: variables.$margin-small;
  width: 21px;
}

.id {
  font-size: variables.$font-size-h2;
  font-weight: bold;
}

.subTitle {
  color: variables.$color-grey-700;
  font-size: variables.$font-size-small;
  font-weight: 400;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  width: 100%;
}

.createdAtTitle,
.createdAt {
  font-size: variables.$font-size-small;
}

.createdAtTitle {
  color: variables.$color-grey-600;
}

.noteTitle {
  display: block;
  font-weight: 500;
  margin-bottom: variables.$margin-small;
}

.deleteButton {
  margin-top: variables.$margin-common;
}
