@use 'src/styles/variables';

$dot-size: 6px;

.loader {
  display: flex;

  > div {
    animation: scale_animation 1s infinite ease-in-out both;
    border-radius: 100%;
    display: inline-block;
    height: $dot-size;
    margin-right: 2px;
    width: $dot-size;
  }
}

.loader .dot1 {
  animation-delay: -0.32s;
}

.loader .dot2 {
  animation-delay: -0.16s;
}

.lightThemeLoader {
  > div {
    background-color: variables.$color-white;
  }
}

.darkThemeLoader {
  > div {
    background-color: variables.$color-black;
  }
}

@keyframes scale_animation {
  0%,
  80%,
  100% {
    transform: scale(0.5);
  }

  40% {
    transform: scale(1);
  }
}
