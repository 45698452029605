@use 'src/styles/variables';

.container {
  background-color: rgba(variables.$color-grey-300, 0.5);
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 32px;
  position: relative;
  width: 32px;
}

.icon {
  color: variables.$color-white;
  font-size: 14px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
