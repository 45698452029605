// fonts
$font-family: 'Roboto', sans-serif;
$font-size-extra-small: 10px;
$font-size-small: 12px;
$font-size-common: 14px;
$font-size-large: 16px;
$font-size-h2: 16px;
$font-size-h1: 18px;
$font-size-large-title: 20px;
$font-size-very-large: 32px;

// margins/paddings
$margin-extra-small: 4px;
$margin-small: 8px;
$margin-common: 16px;
$margin-large: 24px;

// colors
$color-white: #fff;
$color-black: #000;
$color-black-5: rgba(0, 0, 0, 0.05);
$color-black-10: rgba(0, 0, 0, 0.1);
$color-black-16: rgba(0, 0, 0, 0.16);
$color-black-80: rgba(0, 0, 0, 0.8);
$color-light-blue-100: #f0faff;
$color-light-blue-300: #b3e4ff;
$color-light-blue-500: #4cbfff;
$color-blue-grey-50: #f7f8fa;
$color-blue-grey-100: #c1d6f2;
$color-blue-grey-400: #b1bace;
$color-blue-grey-500: #919fba;
$color-blue-grey-600: #808080;
$color-blue-grey-800: #4d4f5c;
$color-blue-50: #cee5ff;
$color-blue-100: #f0f7ff;
$color-blue-200: #e5f2ff;
$color-blue-400: #80bdff;
$color-blue-500: #4ca2ff;
$color-blue-600: #007aff;
$color-grey-100: #f7f7f7;
$color-grey-200: #f2f2f2;
$color-grey-300: #e8e8e8;
$color-grey-400: #bfbfbf;
$color-grey-500: #a6a6a6;
$color-grey-600: #808080;
$color-grey-700: #595959;
$color-grey-800: #4d4d4d;
$color-red-50: #ffdede;
$color-red-100: #fff1f0;
$color-red-200: #ffe7e5;
$color-red-400: #ff8680;
$color-red-500: #ff564c;
$color-red-600: #ff0e00;
$color-green-50: #def6e4;
$color-green-100: #f3fcf3;
$color-green-200: #e1fae2;
$color-green-300: #c2f0c3;
$color-green-400: #67aa6e;
$color-green-500: #70dc74;
$color-green-600: #34cd3a;
$color-green-700: #238f28;
$color-violet-50: #dfd9ff;
$color-violet-100: #b367ff;
$color-orange-50: #ffeede;
$color-orange-100: #fff9f0;
$color-orange-200: #fff0db;
$color-orange-400: #ffca80;
$color-orange-500: #ffb74c;
$color-orange-600: #ff9500;
$color-yellow-100: #fff7ce;
$color-yellow-500: #ffd033;
$color-pink-50: #ffcef1;
$color-pink-100: #f559f9;
$color-pink-500: #ee5da5;
$color-indigo-200: #eaebfb;
$color-indigo-500: #6b76e0;
$color-purple-500: #a64cff;

// borders
$border-radius: 8px;
$border-radius-small: 4px;

// key parameters
$navigation-hover-width: 280px;
$border-table-width: $margin-small;
$border-full-screen-table-width: $margin-common;
$table-cell-padding: 12px;
// if you need to change variables $navigation-width and $main-layout-padding, you also need to change the values in the
// src/components/common/Block/BlockTable/components/StickyHeader/StickyHeader.tsx file
$navigation-width: 72px;
$main-layout-padding: $margin-large;
$main-layout-small-padding: 12px;

// Document sidebar
$document-sidebar-width: 320px;

// Lightbox
$color-lightbox-button-hover: rgba(100, 100, 100, 0.5);
$lightbox-vertical-margin: 68px;

//Navigation
$vertical-sidebar-padding: 32px;
$horizontal-sidebar-padding: $margin-common;

//Dropdown
$dropdown-height: 42px;

// Data card
$data-card-value-fix-width-for-truncate: 266px;
