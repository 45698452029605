@use 'src/styles/variables';
@use 'src/styles/mixins';

.pickerTouched {
  @include mixins.field-touched;
}

.pickerError {
  @include mixins.field-error;
}

.datePickerWrapper {
  position: relative;
}

.dropdown {
  border: 1px solid variables.$color-blue-500;
  border-radius: 8px;
  position: absolute;
  top: 100%;
  transform: translateY(4px);
}
