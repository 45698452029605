@use 'src/styles/variables';
@use 'src/styles/mixins';

.info {
  color: variables.$color-grey-500;
  flex-grow: 1;
  font-size: variables.$font-size-small;

  > *:not(:last-child) {
    margin-right: 8px;
  }
}

.status {
  $size: 8px;
  border-radius: 50%;
  display: inline-block;
  height: $size;
  width: $size;
}

.statusColorGrey {
  background-color: variables.$color-grey-500;
}

.statusColorGreen {
  background-color: variables.$color-green-500;
}

.statusColorRed {
  background-color: variables.$color-red-500;
}

.statusColorYellow {
  background-color: variables.$color-yellow-500;
}
