@use 'src/styles/variables';

$padding: variables.$margin-common;

.tab {
  align-items: center;
  display: flex;
}

.tabsWrapper {
  margin: -#{$padding};
  padding: $padding;
}
