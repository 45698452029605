@use 'src/styles/variables';

.tableFilter {
  align-items: center;
  background-color: variables.$color-white;
  border: 1px solid variables.$color-grey-200;
  border-radius: variables.$border-radius;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  min-height: 48px;
  width: 100%;
}

.right {
  align-items: center;
  display: flex;
  padding: 0 variables.$margin-common;
}

.closeAllFilter {
  border-right: 1px solid variables.$color-grey-300;
  margin-right: variables.$margin-common;
  padding-right: variables.$margin-common;
}

.left {
  cursor: pointer;
  display: flex;
  flex: 1 1;
  justify-content: left;
  margin-left: variables.$margin-small;
}

.filtersContainer {
  position: relative;
}

.filterContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
