@use 'src/styles/variables';

.container {
  align-items: flex-start;
  border: 1px solid variables.$color-grey-200;
  border-radius: variables.$border-radius;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: variables.$margin-common;
  row-gap: variables.$margin-common;
  width: 100%;

  &.noMessage {
    border: 0;
    padding: 0;
  }
}

.message {
  font-weight: 500;
}
