@use 'src/styles/variables';

.status {
  border-radius: 4px;
  font-size: variables.$font-size-small;
  padding: 8px;

  > *:not(:last-child) {
    margin-right: 8px;
  }
}

.statusGrey {
  background-color: variables.$color-grey-100;

  > svg {
    color: variables.$color-grey-500;
  }
}

.statusGreen {
  background-color: variables.$color-green-100;

  > svg {
    color: variables.$color-green-400;
  }
}

.statusRed {
  background-color: variables.$color-red-100;

  > svg {
    color: variables.$color-red-400;
  }
}

.statusYellow {
  background-color: variables.$color-yellow-100;

  > svg {
    color: variables.$color-orange-400;
  }
}
