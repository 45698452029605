@use 'src/styles/variables';

.buttonContainer {
  display: flex;
  margin-bottom: variables.$margin-large;

  > button:not(:last-child) {
    margin-right: variables.$margin-small;
  }
}
