@use 'src/styles/variables';

.container {
  display: flex;
}

.subTitle {
  color: variables.$color-grey-600;
}

.icon {
  margin-right: variables.$margin-small;
  position: relative;
  top: 1px;

  svg {
    height: 16px;
  }
}

.rightAlign {
  justify-content: flex-end;

  .textContainer,
  .iconContainer {
    text-align: end;
  }
}
