@use 'src/styles/variables';
@use 'src/styles/mixins';

.button {
  @include mixins.button-reset;
}

.default {
  color: variables.$color-grey-400;

  &:hover {
    color: variables.$color-orange-500;
  }
}

.blocked {
  color: variables.$color-orange-600;
}

.disabled {
  color: variables.$color-grey-300;
  cursor: default;
}
