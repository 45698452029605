@use 'src/styles/variables';

.filterIcon {
  margin-right: variables.$margin-common;
  padding-left: variables.$margin-small;
}

.inputContainer {
  align-items: center;
  display: flex;
  height: 46px;
}

.placeholder {
  color: variables.$color-grey-500;
  font-size: variables.$font-size-common;
  font-weight: 400;
  line-height: 16px;
}
