@use 'src/styles/variables';

.groupItem {
  align-items: center;
  color: variables.$color-black;
  cursor: pointer;
  display: flex;
  min-height: 40px;
  overflow: hidden;
  padding: 0 variables.$margin-small;
  white-space: nowrap;

  .containerTreeLeaf {
    min-height: 40px;
  }
}

.groupItem.active {
  color: variables.$color-blue-600;
}

.groupItem:hover:not(.active) {
  color: variables.$color-blue-500;
}

.groupItem:last-child {
  .treeLeaf {
    border-bottom-left-radius: 50%;
  }

  .treeLeaf::after {
    display: none;
  }
}

.treeLeaf {
  border-bottom: 1px solid variables.$color-grey-300;
  border-left: 1px solid variables.$color-grey-300;
  height: 21px;
  left: calc(50% - 1px);
  position: absolute;
  top: 0;
  width: 12px;

  &::after {
    background-color: variables.$color-grey-300;
    bottom: -19px;
    content: '';
    height: 19px;
    left: -1px;
    position: absolute;
    width: 1px;
  }
}

.containerTreeLeaf {
  color: variables.$color-grey-400;
  display: inline-block;
  margin-right: variables.$margin-small;
  min-width: 24px;
  position: relative;
  text-align: center;
}
