@use 'src/styles/variables';

$left-table-padding: calc(
  #{variables.$main-layout-padding} +
    #{variables.$border-full-screen-table-width}
);

.stickyHeader {
  display: none;
  padding-left: $left-table-padding;
  position: fixed;
  z-index: 3;

  &.sticky {
    background: variables.$color-white;
    border-bottom: 1px solid variables.$color-grey-200;
    border-top: 1px solid variables.$color-grey-200;
    display: flex;
  }

  .th {
    box-sizing: border-box;
    color: variables.$color-grey-600;
    font-size: variables.$font-size-small;
    line-height: 16px;
    padding: variables.$table-cell-padding;
    white-space: nowrap;
  }

  .th.right {
    text-align: right;
  }

  .th.left {
    text-align: left;
  }

  .th.center {
    text-align: center;
  }
}

.stickyColumn {
  background-color: variables.$color-white;
  border-right: 1px solid variables.$color-black-5;
  left: calc(#{$left-table-padding} + #{variables.$navigation-width});
  position: sticky;

  &::before {
    background-color: variables.$color-white;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(-100%);
    width: $left-table-padding;
  }
}
