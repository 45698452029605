@use 'src/styles/variables';

.block:last-child {
  margin-bottom: 0;
}

.block:hover {
  .actionButton {
    opacity: 1;
  }
}

.titleContainer {
  align-items: center;
  display: flex;

  .actionButton {
    opacity: 0;
    transition: opacity ease 0.4s;
  }
}

.blockTitle {
  color: variables.$color-black;
  font-weight: 500;
  line-height: 16px;
  margin-right: 12px;
  padding: variables.$margin-small 0;
}
