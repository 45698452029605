@use 'src/styles/variables';
@use 'src/styles/mixins';

$transition-settings: 0.1s ease-in 0s;

.checkbox {
  display: none;
}

.label {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.labelText {
  &.small {
    font-size: variables.$font-size-small;
    margin-left: variables.$margin-extra-small;
  }

  &.medium {
    font-size: variables.$font-size-common;
    margin-left: variables.$margin-small;
  }

  &.large {
    font-size: variables.$font-size-large;
    margin-left: variables.$margin-small;
  }
}

.innerWrapper {
  border-radius: 20px;
  overflow: hidden;
  position: relative;

  &.small {
    @include mixins.switch-size(26px, 16px, 12px, 10px);
  }

  &.medium {
    @include mixins.switch-size(34px, 20px, 16px, 14px);
  }

  &.large {
    @include mixins.switch-size(42px, 24px, 20px, 18px);
  }
}

.inner {
  display: flex;
  margin-left: -100%;
  transition: margin $transition-settings;
  width: 200%;

  &::before,
  &::after {
    width: 50%;
  }

  &::before {
    background-color: variables.$color-blue-500;
  }

  &::after {
    background-color: variables.$color-grey-300;
  }
}

.switcher {
  background: variables.$color-white;
  border-radius: 20px;
  bottom: 0;
  position: absolute;
  top: 0;
  transition: all $transition-settings;
}

.checkbox:checked + .innerWrapper {
  .inner {
    margin-left: 0;
  }

  .switcher {
    right: 0;
  }
}
