@use 'src/styles/variables';
@use 'src/styles/mixins';

.wrapper {
  background-color: variables.$color-white;
  border: 1px solid variables.$color-grey-200;
  border-radius: variables.$border-radius;
  padding: variables.$margin-common variables.$margin-common
    variables.$margin-common 3 * variables.$margin-common;
  position: relative;
}

.wrapperOpenable {
  cursor: pointer;
}

.headerCollapseWrapper {
  @include mixins.collapse-transition(400ms);
}

.headerCollapseContent {
  padding: variables.$margin-common 0;

  > *:not(:last-child) {
    margin-bottom: variables.$margin-common;
  }
}

.tagsContainer {
  margin-top: variables.$margin-small;
}

.tag {
  background-color: variables.$color-blue-grey-400;
  border-radius: 4px;
  box-sizing: border-box;
  color: variables.$color-white;
  display: inline-block;
  font-size: variables.$font-size-small;
  font-weight: bold;
  margin-bottom: variables.$margin-small;
  margin-right: variables.$margin-small;
  min-width: 75px;
  padding: 4px 8px;
  text-align: center;
}

.dataListRow {
  display: flex;
  justify-content: space-between;
  padding: variables.$margin-common 0;

  &:not(:last-child) {
    border-bottom: 1px solid variables.$color-grey-100;
  }
}

.dataListName {
  color: variables.$color-grey-600;
}

.actions {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: -#{variables.$margin-small} 0;

  > * {
    flex: 1;
    margin: variables.$margin-small 0;
  }

  > *:not(:last-child) {
    margin-right: variables.$margin-small;
  }
}

.childrenCollapseWrapper {
  $border: 1px solid variables.$color-grey-200;
  @include mixins.collapse-transition(400ms);
  background-color: variables.$color-white;
  border-bottom: $border;
  border-bottom-left-radius: variables.$border-radius;
  border-bottom-right-radius: variables.$border-radius;
  border-left: $border;
  border-right: $border;
  margin: 0 variables.$margin-small;
}

.childrenCollapseContent {
  padding: variables.$margin-common 24px;
}

.comment {
  background-color: variables.$color-white;
  border: 1px solid variables.$color-grey-200;
  border-radius: variables.$border-radius;
  margin-left: -(2 * variables.$margin-common);
  margin-top: variables.$margin-common;
  padding: variables.$margin-common;
  white-space: pre-line;
}
