@use 'src/styles/variables';
@use 'src/styles/mixins';

.button {
  @include mixins.button-reset;
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  transition: background-color linear 0.1s;

  svg {
    transition: color linear 0.1s;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.blue.loading {
    background-color: variables.$color-grey-200;
  }

  &.blue:hover:not(:disabled) {
    background-color: variables.$color-blue-100;
  }

  &.blue:hover:not(:disabled) svg {
    color: variables.$color-blue-500;
  }

  &.red:hover:not(:disabled) {
    background-color: variables.$color-red-100;
  }

  &.red:hover:not(:disabled) svg {
    color: variables.$color-red-500;
  }

  &.small {
    height: 24px;
    width: 24px;
  }

  &.big {
    height: 32px;
    width: 32px;
  }

  &.withoutBackground:hover:not(:disabled) {
    background-color: transparent;
  }
}
