@use 'src/styles/variables';

.subHeader {
  column-gap: 12px;
  display: flex;
  font-size: variables.$font-size-common;
  font-weight: 400;
  line-height: 16px;
}

.label {
  color: variables.$color-grey-600;
}

.value {
  color: variables.$color-grey-800;
}

.affiliateLabelWrapper {
  display: flex;
  column-gap: 4px;
}
