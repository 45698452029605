@use 'src/styles/variables';

.content {
  margin-bottom: variables.$margin-large;
}

.reason {
  background-color: variables.$color-orange-100;
  border: 1px solid variables.$color-orange-400;
  border-radius: variables.$border-radius-small;
  box-sizing: border-box;
  margin-top: variables.$margin-common;
  padding: 8px;
}
