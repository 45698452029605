@use 'src/styles/variables';

$modal-width: 560px;
$modal-box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.08);

.modalHeader {
  align-items: center;
  border-bottom: 1px solid variables.$color-grey-200;
  display: flex;
  font-size: variables.$font-size-h2;
  font-weight: 700;
  justify-content: space-between;
  line-height: 24px;
  padding: 16px;
}

.content {
  height: calc(100vh - 80px - #{variables.$margin-common * 2});
  overflow-y: auto;
  padding: 16px;
}

.subTitle {
  color: variables.$color-grey-700;
  font-size: variables.$font-size-small;
  font-weight: 400;
}

.container {
  align-items: flex-start;
  display: flex;

  &.clickable {
    align-items: center;
  }
}

.actionBtn {
  margin-right: variables.$margin-common;
}

.iconContainer {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-right: variables.$margin-small;
  width: 24px;
}

.overlay {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.modalContent {
  background-color: variables.$color-white;
  border: 0;
  border-radius: 0;
  bottom: 0;
  box-shadow: $modal-box-shadow;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX($modal-width);
  transition: transform 300ms ease-in-out;
  width: $modal-width;

  &.afterOpen {
    transform: translateX(0);
  }

  &.beforeClose {
    transform: translateX($modal-width);
  }

  &:focus {
    outline: none;
  }
}
