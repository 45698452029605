@use 'src/styles/variables';

.dataListRow {
  display: flex;
  font-size: variables.$font-size-small;
  justify-content: space-between;
  padding: 8px 0;

  &:not(:last-child) {
    border-bottom: 1px solid variables.$color-grey-100;
  }

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }
}

.dataListName {
  color: variables.$color-grey-600;
}
