.columns {
  display: flex;

  > div {
    min-width: 350px;
    width: 50%;
  }

  > div:not(:last-child) {
    margin-right: 24px;
  }
}
