@use 'src/styles/variables';

.wrapper {
  max-width: 740px;

  > div,
  a {
    margin-bottom: variables.$margin-small;
  }
}
