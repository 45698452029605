@use 'src/styles/variables';

.actionsContainer {
  display: flex;
  gap: variables.$margin-common;
}

.filterContainer {
  margin-bottom: variables.$margin-small;
}
