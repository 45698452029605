@use 'src/styles/variables';

.content {
  border: 1px solid variables.$color-grey-200;
  border-radius: variables.$border-radius;
  padding: variables.$margin-common;
}

.emptyText {
  font-size: variables.$font-size-common;
  font-weight: 500;
  line-height: 16px;
}

.buttonsContainer {
  display: flex;
  margin-top: variables.$margin-common;
}
