@use 'src/styles/variables';

.counter {
  background-color: variables.$color-grey-200;
  border-radius: variables.$border-radius-small;
  color: variables.$color-grey-600;
  display: flex;
  font-size: variables.$font-size-small;
  font-weight: 500;
  height: 20px;
  margin-left: variables.$margin-extra-small;
  padding: 0 variables.$margin-small;

  &.active {
    background-color: variables.$color-blue-500;
    color: variables.$color-white;
  }

  .plus {
    margin-left: 2px;
  }
}
