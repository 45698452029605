@use 'src/styles/variables';

.container {
  border: 1px solid transparent;
  border-radius: variables.$border-radius-small;
  cursor: pointer;
  padding: 12px;

  &:hover {
    border-color: variables.$color-blue-500;
  }
}

.containerImg {
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
}

.icon {
  width: 13px;
}

.img {
  border-radius: variables.$border-radius-small;
  bottom: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.date {
  font-size: variables.$font-size-small;
  line-height: 16px;
  margin-bottom: variables.$margin-extra-small;
}

.responsiveImgContainer {
  height: 136px;
  margin-bottom: variables.$margin-small;
  position: relative;
}

.note {
  -webkit-box-orient: vertical;
  color: variables.$color-grey-600;
  // stylelint-disable-next-line value-no-vendor-prefix
  display: -webkit-box;
  font-size: variables.$font-size-small;
  -webkit-line-clamp: 2;
  line-height: 16px;
  overflow: hidden;
}
