@use 'src/styles/variables';

.container {
  align-items: center;
  column-gap: 10px;
  display: flex;
  justify-content: space-between;

  &.rightIconPosition .linkWithIcon {
    order: 1;
  }
}

.iconLinkButton svg {
  width: 12px;
}
