@use 'src/styles/variables';

.container {
  align-items: center;
  column-gap: 4px;
  display: inline-flex;

  &.withBigGap {
    column-gap: 6px;
  }
}

.iconWithDefaultColor {
  color: variables.$color-grey-600;
}

.bigSizeIcon {
  font-size: 12px;
}

.smallSizeIcon {
  font-size: 10px;
}
