@use 'src/styles/variables';

/* stylelint-disable selector-class-pattern */
:global(.Toastify__toast-container) {
  box-sizing: content-box;
  padding: 16px;
  width: 320px;
}

:global(.Toastify__toast) {
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  color: variables.$color-black;
  cursor: auto;
  display: block;
  font-family: variables.$font-family;
  font-size: 14px;
  line-height: 20px;
  min-height: auto;
  overflow: visible;
  padding: 16px 16px 20px;
}

:global(.Toastify__toast--error) {
  border: 1px solid variables.$color-red-500;

  :global(.Toastify__close-button) {
    border-color: variables.$color-red-500;
  }
}

:global(.Toastify__toast--error) :global(.Toastify__close-button) {
  > svg {
    fill: variables.$color-red-500;
  }
}

:global(.Toastify__toast--success) {
  border: 1px solid variables.$color-green-500;
}

:global(.Toastify__close-button) {
  align-items: center;
  background: variables.$color-white;
  border: 1px solid variables.$color-grey-300;
  border-radius: 50px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.08);
  color: variables.$color-grey-500;
  display: flex;
  font-size: variables.$font-size-common;
  height: 24px;
  justify-content: center;
  padding-left: 1px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  width: 24px;

  > svg {
    height: 16px;
    width: 16px;
  }
}

:global(.Toastify__close-button--light) {
  opacity: 1;
}

:global(.Toastify__progress-bar) {
  animation:
    ToastProgressWidthAnimation linear 1 forwards running,
    ToastProgressBorderRadiusAnimation forwards;
  animation-duration: 10s !important;
  border-radius: 0 0 variables.$border-radius variables.$border-radius;
  height: 4px;
}

:global(.Toastify__progress-bar--success) {
  background: variables.$color-green-500;
}

:global(.Toastify__progress-bar--error) {
  background: variables.$color-red-500;
}

@keyframes ToastProgressWidthAnimation {
  0% {
    width: 100%;
  }

  100% {
    width: 0;
  }
}

@keyframes ToastProgressBorderRadiusAnimation {
  0% {
    border-radius: 0 0 variables.$border-radius variables.$border-radius;
  }

  2% {
    border-radius: 0 0 0 variables.$border-radius;
  }

  98% {
    border-radius: 0 0 0 variables.$border-radius;
  }
}

/* stylelint-enable selector-class-pattern */
