@use 'src/styles/variables';

.btnContainer {
  display: flex;
  margin-top: variables.$margin-small;
}

.btn {
  margin-right: variables.$margin-common;

  &:last-child {
    margin-right: 0;
  }
}
