@use 'src/styles/variables';
@use 'src/styles/mixins';

$color-background-opened: variables.$color-blue-100;
$color-border-opened: variables.$color-blue-500;

.dropdownWrapper {
  min-height: variables.$dropdown-height;
  position: relative;
}
