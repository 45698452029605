@use 'src/styles/variables';
@use 'src/styles/mixins';

.headerContent {
  @include mixins.buttons-row;
}

.extendedHeader {
  background-color: initial;
  border: initial;
  border-radius: 0;
  padding: 0;

  + * {
    margin-top: variables.$margin-small;
  }
}
