@use 'src/styles/variables';
@use 'src/styles/mixins';

.mainContainer {
  display: flex;
}

.imageContainer {
  border-radius: variables.$border-radius-small;
  display: flex;
  flex-shrink: 0;
  height: 104px;
  margin-right: 16px;
  overflow: hidden;
  position: relative;
  width: 104px;

  &.darkened::after {
    background-color: rgba(0, 0, 0, 0.2);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.image {
  border-radius: variables.$border-radius-small;
  object-fit: cover;
  width: 100%;
}

.contentContainer {
  flex-grow: 1;
  overflow: hidden;
}

.titleBlock {
  display: flex;
  font-size: variables.$font-size-h2;
  justify-content: space-between;
  line-height: 14px;
  margin-bottom: variables.$margin-common;
}

.titleTextContainer {
  display: flex;
  margin-right: 16px;
  overflow: hidden;
}

.title {
  width: 100%;
}

.errorIcon {
  align-items: center;
  color: currentColor;
  display: flex;
  font-size: variables.$font-size-common;
  height: 16px;
  margin-right: variables.$margin-extra-small;
}

.deleteButton {
  @include mixins.button-reset;
  color: variables.$color-grey-500;
  font-size: variables.$font-size-common;
  height: 16px;
  width: 16px;

  &:hover {
    color: variables.$color-red-500;
  }

  &.disabledDeleteButton {
    pointer-events: none;
  }
}

.errorInput {
  color: variables.$color-red-500;

  .title {
    width: calc(100% - 16px);
  }
}
