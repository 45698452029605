@use 'src/styles/variables';
@use 'src/styles/mixins';

.externalPaymentId {
  max-width: 350px;
  overflow: hidden;
}

.btn {
  @include mixins.button-reset;
  align-items: center;
  background: none;
  border: 0;
  border-radius: 4px;
  column-gap: 5px;
  display: flex;
  padding: 0 0 0 5px;
}

.btn.rightIconPosition {
  padding-left: 0;

  .iconContainer {
    order: 1;
  }
}

.btn {
  position: relative;

  &::before {
    border-radius: 4px;
    content: '';
    height: 100%;
    left: 0;
    padding: 4px 0;
    position: absolute;
    top: 0;
    transform: translateY(-4px);
    width: 100%;
    z-index: -1;
  }

  &:hover:not(:active)::before {
    background: variables.$color-grey-100;
  }

  &:hover:not(.rightIconPosition):not(:active)::before {
    background: variables.$color-grey-100;
  }

  &:hover svg {
    color: variables.$color-grey-500;
  }
}

.btn:active {
  &::before {
    background: variables.$color-blue-100;
  }

  &:not(.rightIconPosition) {
    background-color: variables.$color-blue-100;
  }

  &.rightIconPosition {
    color: variables.$color-blue-500;
  }

  svg {
    color: variables.$color-blue-500;
  }
}

.btn svg {
  font-size: 14px;
}
