@use 'src/styles/variables';

.header {
  margin-bottom: variables.$margin-large;

  &.withBorder {
    border: 1px solid variables.$color-grey-200;
    border-radius: variables.$border-radius;
    padding: variables.$margin-common;
  }
}

.emptyText {
  font-size: variables.$font-size-common;
  font-weight: 500;
  line-height: 16px;

  + * {
    margin-top: variables.$margin-common;
  }
}

.buttonsContainer {
  display: flex;
}
