@use 'src/styles/variables';

@media (max-width: 1580px) {
  .limits {
    flex-direction: column;

    .dailyLimit,
    .monthlyLimit {
      width: inherit;
    }

    .dailyLimit {
      margin: 0 0 variables.$margin-small;
    }
  }
}

@media (max-width: 1400px) {
  .multiCharts {
    flex-direction: column;
  }

  .chartDepositsWithdrawals {
    margin: 0 0 16px;
  }
}
