@use 'src/styles/variables';

$animation-duration: 300ms;

@keyframes showModal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes hideModal {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.overlay {
  align-items: center;
  animation: showModal $animation-duration ease-out;
  background-color: variables.$color-black-80;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000; // Toast has z-index: 9999

  &.inActive {
    animation: hideModal $animation-duration;
    opacity: 0;
  }
}

.popupContainer {
  align-items: flex-start;
  background-color: variables.$color-white;
  border: 1px solid variables.$color-grey-200;
  border-radius: variables.$border-radius;
  box-shadow: 0 0 4px 0 variables.$color-black-16;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: variables.$margin-common;
  row-gap: variables.$margin-common;
  width: 304px;
}

.content {
  font-weight: 500;
}

.actionButtons {
  column-gap: variables.$margin-small;
  display: flex;
  width: 100%;
}

.buttonMaxWidth {
  width: 100%;
}
