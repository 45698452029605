@use 'src/styles/variables';

.formFieldWrapper {
  position: relative;
}

.label {
  display: block;
  font-size: variables.$font-size-common;
  font-weight: 500;
  line-height: #{variables.$font-size-common + 2px};
  margin-bottom: variables.$margin-small;
}
