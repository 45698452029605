@use 'src/styles/variables';

.container {
  align-items: center;
  background: variables.$color-white;
  border: 1px solid variables.$color-grey-200;
  border-radius: variables.$border-radius;
  color: variables.$color-black;
  display: flex;
  font-size: variables.$font-size-small;
  justify-content: center;
  line-height: 16px;
  padding: variables.$margin-common;

  &.loadingContainer > svg {
    color: variables.$color-grey-600;
    height: 16px;
    width: 16px;
  }

  &.errorContainer {
    border-color: variables.$color-red-600;
  }
}

.loadingText,
.errorText {
  color: variables.$color-grey-600;
}

.updateButton {
  margin-left: 10px;
}

.loader {
  font-size: variables.$font-size-h2;
  margin-right: variables.$margin-small;
}
