@use 'src/styles/variables';

.title {
  color: variables.$color-grey-600;
  margin-right: variables.$margin-extra-small;
  min-width: 128px;
}

.value {
  color: variables.$color-black;
  margin-right: variables.$margin-small;
  word-break: break-word;
}

.noValue {
  color: variables.$color-grey-600;
  font-style: italic;
}

.row {
  align-items: flex-start;
  display: flex;

  line-height: 16px;
  padding: variables.$margin-small 0;
}
