@use 'src/styles/variables';

.progressBarContainer {
  font-size: variables.$font-size-small;
  min-width: 150px;
  width: 100%;
}

.header {
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 2px;

  &.headerBold {
    font-weight: 500;
  }

  &.limitHeader {
    font-size: variables.$font-size-common;
    margin-bottom: variables.$margin-common;
  }
}

.subTitle {
  color: variables.$color-grey-700;
  display: flex;
  font-size: variables.$font-size-small;
  justify-content: space-between;
  line-height: 16px;
  margin-top: variables.$margin-extra-small;
  width: 100%;

  &.limitSubTitle {
    color: variables.$color-grey-600;
  }
}

.bar,
.limitBarWrapper {
  background-color: variables.$color-grey-200;
  border-radius: 8px;
  height: 6px;
  min-width: 8px;
}

.limitBarWrapper {
  overflow: hidden;

  .bar {
    min-width: 0;
  }
}

.barFull {
  background-color: variables.$color-green-500;
}

.barColorBlue {
  background-color: variables.$color-blue-500;
}

.barColorRed {
  background-color: variables.$color-red-500;
}

.barColorYellow {
  background-color: variables.$color-yellow-500;
}

.barColorOrange {
  background-color: variables.$color-orange-500;
}

.barColorGreen {
  background-color: variables.$color-green-500;
}

.barColorIndigo {
  background-color: variables.$color-indigo-500;
}
