@use 'src/styles/variables';

.tabsContainer {
  display: flex;
}

.tab {
  color: variables.$color-grey-600;
  cursor: pointer;
  font-size: variables.$font-size-h2;
  margin-bottom: variables.$margin-large;
  margin-right: 16px;
  transition: all linear 0.1s;

  &:last-child {
    margin-right: 0;
  }

  &.active {
    color: variables.$color-black;
    cursor: default;
  }

  &:not(.active):hover {
    color: variables.$color-blue-500;
  }
}
