@use 'src/styles/variables';
@use 'src/styles/mixins';

@mixin active-field {
  background-color: variables.$color-blue-100;
  border: 1px variables.$color-blue-500 solid;
}

@mixin arrow-transition {
  transition: transform 0.5s;
}

.dropdown {
  align-items: center;
  background-color: variables.$color-white;
  border: 1px variables.$color-grey-300 solid;
  border-radius: variables.$border-radius-small;
  box-sizing: border-box;
  color: variables.$color-grey-500;
  cursor: pointer;
  display: flex;
  font-size: variables.$font-size-small;
  height: 40px;
  outline: none;
  padding: 0 35px 0 variables.$margin-small;

  &:focus {
    @include active-field;
  }
}

.dropdownOpened {
  @include active-field;
  border-radius: variables.$border-radius-small;
}

.dropdownTouched {
  @include mixins.field-touched;
}

.dropdownError {
  @include mixins.field-error;
}

.dropdownDisabled {
  background-color: variables.$color-grey-100;
  color: variables.$color-grey-500;
  cursor: default;
}

.selectedText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.arrow {
  position: absolute;
  right: 16px;
  top: calc(22px - 6px / 2);
}

.arrowDown {
  @include mixins.arrow-down(variables.$color-black-10, 6px);
  @include arrow-transition;
  transform: rotate(0deg);
}

.arrowDownError {
  @include mixins.arrow-down(variables.$color-black, 6px);
}

.arrowOpened {
  @include mixins.arrow-down(variables.$color-blue-500, 6px);
  @include arrow-transition;
  transform: rotate(-180deg);
}

.arrowOpenedCheckbox {
  @include mixins.arrow-down(variables.$color-blue-500, 6px);
}

.errorWrapper {
  margin-top: variables.$margin-small;
}
