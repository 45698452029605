.bold {
  align-items: center;
  display: flex;
  font-weight: 500;
  line-height: normal;
}

.iconWarning {
  font-size: 14px;
  margin-right: 5px;
}
