@use 'src/styles/variables';

$border-color: variables.$color-grey-200;
$border-color-hovered: variables.$color-light-blue-300;
$border-color-active: variables.$color-light-blue-500;

.datePeriodLabel {
  align-items: center;
  border: 1px solid $border-color;
  border-radius: variables.$margin-extra-small;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 24px;
  padding-left: variables.$margin-small;
  transition: all ease 0.1s;

  .closeButtonContainer {
    border-left: 1px solid $border-color;
    transition: all ease 0.1s;
  }

  &.active {
    background-color: variables.$color-light-blue-100;
    border-color: $border-color-active;
  }

  &:hover {
    background-color: variables.$color-light-blue-100;
    border-color: $border-color-hovered;
  }
}

.labelText {
  color: variables.$color-grey-600;
}

.period {
  margin-left: variables.$margin-extra-small;
  padding-right: variables.$margin-small;
}

.datePeriodLabel.active {
  .closeButtonContainer {
    border-color: $border-color-active;
  }
}

.datePeriodLabel:hover {
  .closeButtonContainer {
    border-color: $border-color-hovered;
  }
}
