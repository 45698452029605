@use 'src/styles/variables';
@use 'src/styles/mixins';

@include mixins.media(small) {
  .sidebarWrapper {
    left: -200px;
    transition: left ease 0.5s;

    &:hover {
      left: 0;

      &::before {
        content: '';
        height: 0;
        width: 0;
      }
    }

    &::before {
      background-color: variables.$color-black-10;
      border-radius: 0 50% 50% 0;
      box-sizing: border-box;
      content: '>';
      height: 25px;
      left: 0;
      padding: 2px 0;
      position: fixed;
      text-align: center;
      top: 50%;
      width: 25px;
    }
  }
}
