@use 'src/styles/variables';
@use 'src/styles/mixins';

.menu {
  @include mixins.dropdown-wrapper;
}

.searchInputWrapper {
  margin-bottom: variables.$margin-extra-small;
}

.searchWarningText {
  color: variables.$color-grey-600;
  font-size: variables.$font-size-small;
  line-height: 16px;
  padding: variables.$margin-small;
}

.dropdownLoader {
  border: 0;
  padding: 10px;
}
