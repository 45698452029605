@use 'src/styles/variables';

.loadMoreContainer,
.loadMore {
  border-radius: variables.$border-radius-small;
  font-family: variables.$font-family;
  font-size: variables.$font-size-small;
  padding: variables.$margin-small;
  width: 100%;
}

.loadMore {
  background-color: transparent;
  border: variables.$color-grey-200 1px solid;
  color: variables.$color-black;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:not(:disabled):hover {
    background-color: variables.$color-grey-200;
  }

  &:disabled {
    background-color: variables.$color-grey-200;
    color: variables.$color-grey-600;
  }
}

.loadMoreContainer {
  background: variables.$color-grey-200;
  border: 0;
  box-sizing: border-box;
  color: variables.$color-grey-500;
  line-height: 0;
  pointer-events: none;
  text-align: center;
}

.error {
  border: 1px solid variables.$color-red-500;
  color: variables.$color-black;
  line-height: 14px;
  pointer-events: auto;
}

.loader {
  font-size: variables.$font-size-h2;
  margin-left: variables.$margin-small;
  vertical-align: 0;
}
