@use 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.limits {
  display: flex;
  margin-bottom: variables.$margin-small;
}

.dailyLimit,
.monthlyLimit {
  background-color: variables.$color-white;
  border: 1px solid variables.$color-grey-200;
  border-radius: variables.$border-radius;
  display: flex;
  padding: variables.$margin-common;
  width: 50%;
}

.dailyLimit {
  margin-right: variables.$margin-small;
}

.multiChartsContainer {
  background-color: variables.$color-white;
  border: 1px solid variables.$color-grey-200;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: variables.$margin-common;
}

.multiCharts {
  border-bottom: 1px solid variables.$color-grey-200;
  display: flex;
  margin-bottom: variables.$margin-common;
  padding-bottom: variables.$margin-common;
}

.chartTitle {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: variables.$margin-common;
}

.chartBetsWins,
.chartDepositsWithdrawals {
  width: 100%;
}

.chartDepositsWithdrawals {
  margin-right: 40px;
}

.profit {
  align-items: center;
  display: flex;
}

.profitValue {
  font-size: 12px;
}
