@use 'src/styles/variables';
@use 'src/styles/mixins';

.textarea {
  @include mixins.input;
  height: 48px;
  line-height: 16px;
  overflow-y: auto;
  padding: variables.$margin-small;
  resize: none;
  vertical-align: top;

  &.resizable {
    resize: vertical;
  }

  &::-webkit-resizer {
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='9' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='8.00001' y1='4.70711' x2='4.70716' y2='8.00004' stroke='black' stroke-opacity='0.1' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cline x1='8' y1='0.707107' x2='0.707107' y2='8' stroke='black' stroke-opacity='0.1' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
  }
}
