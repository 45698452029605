@use 'src/styles/variables';

.container {
  align-items: center;
  color: variables.$color-grey-500;
  column-gap: variables.$margin-small;
  display: flex;

  svg {
    height: 16px;
    width: 16px;
  }
}
