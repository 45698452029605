@use 'src/styles/variables';
@use 'src/styles/mixins';

.referralLink {
  max-width: variables.$data-card-value-fix-width-for-truncate;
}

.buttonContent {
  align-items: center;
  display: flex;

  svg {
    color: variables.$color-blue-500;
  }

  &:hover svg {
    color: variables.$color-blue-600;
  }
}

.iconContainer {
  margin-left: variables.$margin-small;
}
