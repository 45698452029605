@use 'src/styles/variables';

.filesContainer {
  display: flex;
  flex-direction: column;
  gap: variables.$margin-large;
  margin-bottom: variables.$margin-large;
}

.inputFile {
  margin-bottom: variables.$margin-large;
}
