@use 'src/styles/variables';

.filterContainer {
  margin-bottom: variables.$margin-small;
}

.actionsContainer {
  display: flex;
  gap: 16px;
}
