@use 'src/styles/variables';
@use 'src/styles/mixins';

.container {
  background: variables.$color-white;
  border-radius: variables.$border-radius;
  overflow-x: auto;
  padding-bottom: 16px;

  &.isEmpty {
    padding: 0;
  }
}

.button {
  margin: variables.$margin-common variables.$margin-small 0;
  width: calc(100% - 16px);
}

.tableStatus {
  margin-top: 16px;
}
