@use 'src/styles/variables';

.container {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.iconLinkButton {
  margin-left: 10px;
  padding-top: 4px;

  svg {
    width: 12px;
  }
}
