@use 'src/styles/variables';
@use 'src/styles/mixins';

.headerContent {
  @include mixins.buttons-row;
}

.wrapper {
  background-color: variables.$color-white;
  border: 1px solid variables.$color-grey-200;
  border-radius: variables.$border-radius;
  display: flex;
  flex-direction: column;
  padding: 8px 8px 16px;
}

.documentsList {
  margin-bottom: 16px;
}

.documentsBlockGrid {
  .documentsList {
    display: grid;
    grid-template-columns: repeat(auto-fill, calc(100% / 4));
  }
}

.documentsPageGrid {
  .documentsList {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(calc(max(218px, 100%/8)), 1fr)
    );
  }
}
