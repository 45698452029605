@use 'src/styles/variables';

.hidden {
  display: none;
}

.container {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  width: 100%;
}

.container.error {
  .label {
    background-color: variables.$color-red-100;
    border-color: variables.$color-red-500;
  }

  .label:hover .icon {
    color: variables.$color-red-500;
  }

  .icon {
    color: variables.$color-red-500;
  }
}

.container.dragActive {
  .label {
    background-color: variables.$color-blue-100;
    border-color: variables.$color-blue-500;
    border-style: solid;
  }

  .icon {
    color: variables.$color-blue-500;
  }

  .button {
    color: initial;
  }
}

.innerContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  pointer-events: none;
}

.label {
  align-items: center;
  border: 1px dashed variables.$color-grey-300;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  min-height: 168px;
  padding: 39px;
}

.label:hover {
  border-color: variables.$color-blue-500;

  .icon {
    color: variables.$color-blue-500;
  }

  .innerContainer {
    pointer-events: auto;
  }
}

.button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.icon {
  color: variables.$color-grey-400;
  font-size: 32px;
}

.subText {
  color: variables.$color-grey-600;
}
