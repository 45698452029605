@use 'src/styles/variables';

.container {
  align-items: center;
  display: flex;
}

.title {
  margin-right: variables.$margin-extra-small;
}

.questionIcon {
  cursor: pointer;
}
