@use 'src/styles/variables';

.chartTable {
  display: table;
  width: 100%;
}

.chartTable:hover {
  .currencyCell,
  .titleCell,
  .valueCell,
  .barCell {
    opacity: 0.25;
  }
}

.rowGroup {
  display: table-row-group;

  .evenRow > div {
    padding-bottom: 12px;
  }
}

.rowGroup:hover {
  .currencyCell {
    opacity: 1;
  }
}

.rowGroup:last-child {
  .evenRow > div {
    padding-bottom: 0;
  }
}

.evenRow,
.oddRow {
  display: table-row;
}

.oddRow:hover,
.evenRow:hover {
  .titleCell,
  .valueCell,
  .barCell {
    opacity: 1;
  }
}

.oddRow > div {
  padding-bottom: 2px;
}

.currencyCell {
  display: table-cell;
  font-size: variables.$font-size-small;
  font-weight: 500;
  line-height: normal;
  padding-right: variables.$margin-common;
  text-transform: uppercase;
  transition: opacity 0.2s ease;
  vertical-align: top;
}

.titleCell,
.valueCell {
  display: table-cell;
  font-size: variables.$font-size-small;
  line-height: normal;
  transition: opacity 0.2s ease;
  vertical-align: top;
}

.titleCell {
  padding-right: variables.$margin-common;
}

.valueCell {
  padding-right: 40px;
}

.barCell {
  display: table-cell;
  transition: opacity 0.2s ease;
  vertical-align: middle;
  width: 100%;

  [class*='progressBarContainer'] {
    min-width: 0;
  }
}
