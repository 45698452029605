@use 'src/styles/variables';

.container {
  align-items: center;
  background: variables.$color-grey-100;

  border-radius: 4px;
  box-sizing: border-box;
  color: variables.$color-grey-600;
  display: flex;
  font-size: variables.$font-size-common;
  height: 32px;
  line-height: 16px;
  margin-right: variables.$margin-small;
  padding: variables.$margin-small;
}

.selectedFilterValue {
  color: variables.$color-black;
  margin-left: calc(#{variables.$margin-small} / 2);
  margin-right: variables.$margin-small;
}

.input {
  border: 0;
  font-size: variables.$font-size-common;
  font-weight: 400;
  left: 100%;
  line-height: 16px;
  outline: none;
  position: absolute;
  width: 150px;

  &:focus:not(.inputError) {
    outline: none;
  }
}
