@use 'src/styles/variables';

.wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.message {
  margin-bottom: 8px;
}

.button {
  background: variables.$color-grey-200;
  height: 24px;
  padding: 4px 8px;

  &:hover {
    background: variables.$color-grey-300;
  }
}
