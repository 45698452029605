@use 'src/styles/variables';
@use 'src/styles/mixins';

.footerContainer {
  align-items: center;
  border-top: 1px solid variables.$color-grey-300;
  display: flex;
  justify-content: space-around;
  padding: variables.$margin-common;
}
