@use 'src/styles/variables';
@use 'src/styles/mixins';

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 12px 8px;
}

.button {
  @include mixins.button-reset;
  align-items: center;
  border-radius: variables.$border-radius-small;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;

  &:hover {
    background-color: variables.$color-blue-100;
  }
}

.icon {
  color: variables.$color-grey-400;
  height: 16px;
  width: 16px;
}

.dateContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.month {
  cursor: pointer;
  margin-right: 8px;
}

.year {
  cursor: pointer;
}

.captionContainer {
  position: relative;
}
