@use 'src/styles/variables';

.buttonLinksWrapper {
  margin-top: 32px;
}

.buttonLink {
  display: block;
  margin-top: variables.$margin-common;
}
