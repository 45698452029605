@use 'src/styles/variables';

.affiliatesList {
  margin-bottom: variables.$margin-common;
}

.affiliateItem {
  border: 1px solid variables.$color-grey-300;
  border-radius: variables.$border-radius;
  margin-bottom: variables.$margin-common;
  padding: variables.$margin-common;
}

.addAffiliateBtn {
  margin-bottom: variables.$margin-large;
  width: 100%;
}
