@use 'src/styles/variables';

$icon-size: 16px;

.container {
  align-items: flex-start;
  border-radius: variables.$border-radius-small;
  display: flex;
  padding: 8px;

  &.green {
    background-color: variables.$color-green-200;
  }

  &.red {
    background-color: variables.$color-red-200;
  }

  &.orange {
    background-color: variables.$color-orange-200;
  }

  &.blue {
    background-color: variables.$color-blue-200;
  }
}

.cardIcon {
  margin-right: variables.$margin-small;

  :global(.icon) {
    height: $icon-size;
    width: $icon-size;
  }
}

.title {
  font-size: variables.$font-size-small;
  line-height: variables.$font-size-small;

  &.bold {
    font-weight: 500;
  }
}

.text {
  font-size: variables.$font-size-small;
  line-height: 16px;
  margin-top: variables.$margin-extra-small;
}

.contentContainer {
  transform: translateY(($icon-size - variables.$font-size-small) * 0.5);
}
