@use 'src/styles/variables';

.checkboxControl {
  align-items: center;
  background-color: variables.$color-white;
  border: 1px solid variables.$color-grey-300;
  border-radius: variables.$border-radius-small;
  display: flex;
  height: 18px;
  justify-content: center;
  margin: 1px variables.$margin-small 1px 1px;
  transition:
    0.2s border-color,
    0.2s background-color;
  width: 18px;
  z-index: 0;
}

.checked {
  background-color: variables.$color-blue-500;
  border-color: variables.$color-blue-500;

  svg {
    color: variables.$color-white;
    fill: variables.$color-white;
  }
}
