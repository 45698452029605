@use 'src/styles/variables';

.container {
  column-gap: 14px;
  display: flex;
}

.balance {
  position: relative;

  &::after {
    border-radius: variables.$border-radius-small;
    bottom: -2px;
    content: '';
    display: block;
    left: -5px;
    position: absolute;
    right: -5px;
    top: -2px;
    z-index: -1;
  }
}

.creditBalance {
  color: variables.$color-red-600;

  &::after {
    background-color: variables.$color-red-200;
  }
}

.debitBalance {
  color: variables.$color-green-700;

  &::after {
    background-color: variables.$color-green-200;
  }
}
