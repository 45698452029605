/* stylelint-disable max-nesting-depth */
/* stylelint-disable selector-class-pattern */

@use 'src/styles/variables';
@use 'src/styles/mixins';
@import 'react-datepicker/dist/react-datepicker-cssmodules.css';

.datePickerContainer {
  background-color: variables.$color-white;
  z-index: 2;

  :global(.react-datepicker__week) {
    display: flex;
    margin-bottom: variables.$margin-extra-small;

    &:last-child {
      margin-bottom: 0;
    }
  }

  :global(.react-datepicker__day-names) {
    display: flex;
    margin: 0 variables.$margin-small variables.$margin-extra-small;
  }

  :global(.react-datepicker) {
    border: 0;
    border-radius: variables.$border-radius;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  :global(.react-datepicker__year-text--selected),
  :global(.react-datepicker__month-text--selected),
  :global(.react-datepicker__month-text--keyboard-selected),
  :global(.react-datepicker__day--keyboard-selected),
  :global(.react-datepicker__year-text--in-range),
  :global(.react-datepicker__month-text--in-range) {
    background-color: transparent;
    color: initial;
  }

  :global(.react-datepicker__day--today),
  :global(.react-datepicker__month-text--today),
  :global(.react-datepicker__year-text--today) {
    color: variables.$color-blue-600;
    font-weight: 400;

    &:hover {
      color: variables.$color-blue-600;
    }
  }

  :global(.react-datepicker__day),
  :global(.react-datepicker__month-text),
  :global(.react-datepicker__year-text) {
    align-items: center;
    border-radius: variables.$border-radius-small;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    margin: 0;
    width: 40px;

    &:hover {
      background-color: variables.$color-blue-100;
    }
  }

  :global(.react-datepicker__month .react-datepicker__month-text) {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 variables.$margin-small 0 0;
    width: 90px;

    &:last-child {
      margin-right: 0;
    }
  }

  :global(.react-datepicker__year .react-datepicker__year-text) {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
    margin-bottom: variables.$margin-small;
    min-height: 40px;
    width: 272px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  :global(.react-datepicker__year-wrapper) {
    flex-direction: column;
    flex-wrap: nowrap;
    max-height: 320px;
    max-width: initial;
    overflow-y: auto;
  }

  :global(.react-datepicker__month-wrapper) {
    display: flex;
    margin-bottom: variables.$margin-large;

    &:last-child {
      margin-bottom: 0;
    }
  }

  :global(.react-datepicker__day-name) {
    align-items: center;
    color: variables.$color-grey-600;
    display: flex;
    font-size: variables.$font-size-small;
    height: 40px;
    justify-content: center;
    margin: 0;
    text-align: center;
    text-transform: capitalize;
    width: 40px;
  }

  :global(.react-datepicker__header) {
    background-color: transparent;
    border-bottom: 0;
    border-radius: 0;
    margin-bottom: variables.$margin-extra-small;
    padding: 0;
  }

  :global(.react-datepicker__triangle) {
    display: none;
  }

  :global(.react-datepicker__day--outside-month) {
    color: variables.$color-grey-500;
  }

  :global(.react-datepicker__day--selected),
  :global(
    .react-datepicker__day--in-selecting-range:not(
        .react-datepicker__day--in-range
      )
  ),
  :global(.react-datepicker__day--range-end.react-datepicker__day--in-range) {
    background-color: variables.$color-blue-500;
    color: variables.$color-white;

    &:hover {
      background-color: variables.$color-blue-500;
      border-radius: variables.$border-radius-small;
      color: variables.$color-white;
    }
  }

  :global(
    .react-datepicker__day--in-range:not(
        .react-datepicker__day--range-start,
        .react-datepicker__day--range-end
      )
  ),
  :global(
    .react-datepicker__day--in-selecting-range:not(
        .react-datepicker__day--selecting-range-start,
        .react-datepicker__day--in-range
      )
  ) {
    background-color: variables.$color-blue-100;
    border-radius: 0;
    color: variables.$color-black;
  }

  :global(.react-datepicker__year-text--disabled),
  :global(.react-datepicker__month-text--disabled),
  :global(.react-datepicker__day--disabled) {
    color: variables.$color-grey-500;
  }

  :global(.react-datepicker__aria-live) {
    display: none;
  }

  :global(.react-datepicker__month) {
    margin: 0.4rem;
  }
}
