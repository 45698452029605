@use 'src/styles/variables';

.reason {
  margin-bottom: variables.$margin-large;

  .noValue {
    color: variables.$color-grey-600;
    font-style: italic;
  }
}
