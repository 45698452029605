@use 'src/styles/variables';
@use 'src/styles/mixins';

.container {
  position: relative;
}

.menu {
  @include mixins.dropdown-wrapper;
  top: calc(100% + 4px);
  width: auto;
}

.option {
  @include mixins.dropdown-option;
}

.leftPosition {
  left: 0;
}

.rightPosition {
  right: 0;
}
