@use 'src/styles/variables';
@use 'src/styles/mixins';

$button-size: 40px;

.container {
  display: flex;
  transform: translate(-12px, -10px);
}

.approveButton,
.rejectButton {
  @include mixins.button-reset;

  color: variables.$color-grey-500;
  height: $button-size;
  transition: color 0.3s ease;
  width: $button-size;

  &:disabled {
    color: variables.$color-grey-300;
    cursor: default;
  }
}

.approveButton:not(:disabled):hover {
  color: variables.$color-green-500;
}

.rejectButton:not(:disabled):hover {
  color: variables.$color-red-500;
}
