@use 'src/styles/variables';

.iconContainer {
  display: flex;
  justify-content: center;
  width: 16px;
}

.iconColorGrey {
  color: variables.$color-grey-500;
}

.iconColorBlack {
  color: variables.$color-black;
}

.iconColorRed {
  color: variables.$color-red-500;
}

.iconColorPink {
  color: variables.$color-pink-500;
}

.iconColorGreen {
  color: variables.$color-green-500;
}

.iconColorBlue {
  color: variables.$color-blue-500;
}

.iconColorBlueGrey {
  color: variables.$color-blue-grey-500;
}

.iconColorViolet {
  color: variables.$color-violet-100;
}

.iconColorOrange {
  color: variables.$color-orange-500;
}

.iconColorYellow {
  color: variables.$color-yellow-500;
}

.iconColorIndigo {
  color: variables.$color-indigo-500;
}

.iconColorPurple {
  color: variables.$color-purple-500;
}

.iconColorTransparent {
  color: transparent;
}
