@use 'src/styles/variables';

.container {
  align-items: flex-start;
  border-bottom: 1px solid variables.$color-grey-100;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  padding: 8px 0;

  &:last-child {
    border-color: transparent;
  }
}

.title {
  color: variables.$color-grey-600;
}

.value,
.title {
  line-height: 15px;
}

.value {
  text-align: end;

  &.bold {
    font-weight: 500;
  }
}

.noValue {
  color: variables.$color-grey-600;
  font-style: italic;
}
