@use 'src/styles/variables';

.linkContainer {
  align-items: center;
  display: flex;

  &:hover {
    color: variables.$color-blue-600;
  }
}

.linkText,
.icon {
  &.small {
    font-size: variables.$font-size-small;
  }

  &.large {
    font-size: variables.$font-size-common;
  }
}

.linkText {
  line-height: 16px;

  &.withIcon {
    margin-right: variables.$margin-small;
  }

  &.underlined {
    border-bottom: 1px dotted currentColor;
  }
}
