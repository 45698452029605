@use 'src/styles/variables';

$border-line-width: 1px;
$checked-dot-size: 8px;
$radio-size: 16px;

.pseudoRadio {
  align-items: center;
  background: transparent;
  border: $border-line-width solid variables.$color-grey-300;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  height: $radio-size;
  justify-content: center;
  transition: all ease 0.1s;
  width: $radio-size;

  &::after {
    background: variables.$color-blue-500;
    border-radius: 50%;
    content: '';
    display: block;
    height: 0;
    transition: all ease 0.1s;
    width: 0;
  }
}

.disabledPseudoRadio {
  background: variables.$color-grey-100;
}

.radio {
  appearance: none;
  margin: 0;
}

.radio:checked + .pseudoRadio {
  background: variables.$color-blue-100;
  border-color: variables.$color-blue-500;

  &::after {
    height: $checked-dot-size;
    width: $checked-dot-size;
  }
}

.radio:checked + .disabledPseudoRadio {
  background: variables.$color-grey-100;
  border-color: variables.$color-grey-300;

  &::after {
    background: variables.$color-grey-300;
  }
}

.label {
  align-items: center;
  cursor: pointer;
  display: flex;

  &.disabledLabel {
    cursor: default;
  }

  &:hover .pseudoRadio {
    border-color: variables.$color-blue-500;
  }

  &:hover .disabledPseudoRadio {
    background: variables.$color-grey-100;
    border-color: variables.$color-grey-300;
  }
}

.labelText {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  margin-left: variables.$margin-small;

  .disabledLabel & {
    color: variables.$color-grey-600;
  }
}
