@use 'src/styles/variables';

.container {
  transform-box: fill-box;
}

.background {
  fill: transparent;
  opacity: 0.5;
  stroke: variables.$color-grey-300;
}

.progressBar {
  fill: transparent;
  stroke: variables.$color-grey-300;
  transform: rotate(-90deg);
  transform-origin: center;
}
