@use 'src/styles/variables';
@use 'src/styles/mixins';

$block-animation-duration: 250ms;
$border-size: 1px;

@mixin header-background {
  background-color: variables.$color-white;
  border: $border-size solid variables.$color-grey-200;
  border-radius: variables.$border-radius;
  padding: variables.$margin-common;
}

@mixin arrow-rotation($deg) {
  transform: rotate($deg);
  transform-origin: center 9px;
  transition: transform $block-animation-duration;
}

.block {
  margin-bottom: 24px;
}

.headerContainer {
  background-color: variables.$color-white;
  border: $border-size solid #f2f2f2;
  border-radius: variables.$border-radius;
  display: flex;
  font-size: variables.$font-size-h2;
  font-weight: bold;
  padding: (variables.$margin-common - $border-size) variables.$margin-common;

  &.clickable {
    cursor: pointer;
  }
}

.headerContentContainer {
  width: 100%;
}

.headerTitle {
  align-items: center;
  display: flex;
}

.headerContent {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: space-between;
  line-height: 24px;
}

.buttonsContainer {
  height: 42px;
}

.headerCollapseContent:not(:empty) {
  padding-top: variables.$margin-small;
}

.subHeader {
  margin-top: variables.$margin-small;
}

.extendedHeader {
  @include header-background;
}

.childrenCollapseContent {
  margin-top: variables.$margin-small;
}

.arrowIcon {
  @include arrow-rotation(0deg);
  margin: 0 8px;
}

.arrowIconOpened {
  @include arrow-rotation(-180deg);
}

.headerCollapseWrapper {
  @include mixins.collapse-transition($block-animation-duration);
}

.childrenCollapseWrapper {
  @include mixins.collapse-transition($block-animation-duration);
}

.extendedHeader + .loadingWrapper {
  margin-top: variables.$margin-small;
}

.loadingWrapper {
  margin: 0 variables.$margin-small;
}

.goBackContainer {
  margin-right: variables.$margin-common;
}
