@use 'src/styles/variables';
@use 'src/styles/mixins';

// вынем все в переменные
$header-small-padding: variables.$main-layout-small-padding;
@include mixins.media(medium) {
  .header {
    box-sizing: border-box;
    flex-direction: column;
    margin-left: calc(#{$header-small-padding} * -1);
    padding: 20px $header-small-padding;
    width: calc(100% + #{$header-small-padding} * 2);
  }

  .markersUncoloredWrapper {
    display: none;
    margin-left: 0;
    margin-top: variables.$margin-small;

    .marker {
      margin-bottom: variables.$margin-small;
    }
  }

  .markersWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .marker {
      margin-right: 0;
    }
  }
}
