@use 'src/styles/variables';

.small {
  margin-bottom: variables.$margin-small;
}

.medium {
  margin-bottom: variables.$margin-common;
}

.large {
  margin-bottom: variables.$margin-large;
}
