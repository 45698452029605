@use 'src/styles/variables';

.container {
  column-gap: 12px;
  display: flex;
  line-height: 16px;
}

.titles {
  color: variables.$color-blue-grey-600;
  display: flex;
  flex-direction: column;
}

.values {
  display: flex;
  flex-direction: column;
}
