@use 'src/styles/variables';

.downloadButton {
  background: none;
  border: 0;
  border-radius: 50%;
  color: variables.$color-white;
  cursor: pointer;
  font-size: variables.$font-size-large;
  height: 48px;
  width: 48px;

  &:hover {
    background-color: variables.$color-lightbox-button-hover;
  }
}

.icon {
  height: 24px;
  transform: translateY(1px);
  width: 27px;
}
