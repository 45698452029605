@use 'src/styles/variables';

.container {
  align-items: center;
  display: flex;
}

.status {
  margin-left: variables.$margin-small;
}
