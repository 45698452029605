@use 'src/styles/variables';

.container {
  border: 1px solid variables.$color-grey-300;
  border-radius: variables.$border-radius;
  display: flex;
  flex-direction: column;
  padding: variables.$margin-common;
}

.header {
  display: flex;
  justify-content: space-between;

  &:not(:only-child) {
    margin-bottom: variables.$margin-common;
  }
}

.label {
  cursor: pointer;
  font-weight: 700;
  line-height: 16px;
}
