@use 'src/styles/variables';

.root {
  position: relative;
}

.loadingRootState {
  height: 400px;
  overflow: hidden;
}

.loadingBackground {
  background-color: variables.$color-white;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.loadingContainer {
  align-items: center;
  display: flex;
  font-size: 35px;
  height: 400px;
  justify-content: center;
  left: 0;
  max-height: 70vh;
  position: absolute;
  top: 0;
  width: 100%;
}
