/* stylelint-disable max-nesting-depth */
/* stylelint-disable selector-class-pattern */

@use 'src/styles/variables';

.customLightboxWrapper {
  &.ril__outer {
    background-color: variables.$color-black;
    height: 78%;
    left: 50%;
    max-height: calc(100% - #{variables.$lightbox-vertical-margin} * 2);
    position: relative;
    top: 50%;
    transform: translate(
      calc(-50% - #{variables.$document-sidebar-width}/ 2),
      -50%
    );
    width: calc(75% - #{variables.$document-sidebar-width});
  }

  .ril__image {
    max-width: 100%;
  }

  .ril__inner {
    overflow: hidden;
  }

  .ril-caption {
    display: none;
  }

  .ril-toolbar {
    background: none;
    justify-content: center;
    left: -12.5vw;
    width: 100vw;
  }

  .ril__toolbarLeftSide {
    padding: 0;
  }

  .ril__toolbarRightSide {
    display: flex;
    height: 48px;
    padding: 0;

    .ril-toolbar__item {
      display: flex;
      margin-right: variables.$margin-extra-small;

      &:nth-child(1) {
        margin: 0;
        order: 4;
      }

      &:nth-child(2) {
        order: 3;
      }

      &:nth-child(5) {
        margin-right: 10px;
        order: 5;
        position: absolute;
        right: 0;
      }
    }
  }

  .ril__builtinButton {
    border-radius: 50%;
    height: 48px;
    vertical-align: inherit;
    width: 48px;

    &:hover {
      background-color: variables.$color-lightbox-button-hover;
    }

    &:not(.ril__builtinButtonDisabled) {
      opacity: 1;
    }
  }

  .ril__builtinButtonDisabled {
    cursor: pointer;
    opacity: 1;
  }

  .ril__navButtons {
    opacity: 1;
  }

  .ril__navButtonNext {
    background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-right' class='svg-inline--fa fa-chevron-right ' role='img' fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3Cpath d='M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat center;
    background-size: 15px 20px;
  }

  .ril__navButtonPrev {
    background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-left' class='svg-inline--fa fa-chevron-left ' role='img' fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3Cpath d='M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat center;
    background-size: 15px 20px;
  }

  .ril__rotateButton {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.8438 1.26562V7.82812C19.8438 8.40234 19.3926 8.8125 18.8594 8.8125H12.2969C11.7227 8.8125 11.3125 8.40234 11.3125 7.82812C11.3125 7.29492 11.7227 6.84375 12.2969 6.84375H16.3984C15.168 4.50586 12.707 2.90625 10 2.90625C5.98047 2.90625 2.78125 6.14648 2.78125 10.125C2.78125 14.1445 5.98047 17.3438 10 17.3438C11.6816 17.3438 13.3223 16.7695 14.5938 15.7031C15.0039 15.334 15.6191 15.416 15.9883 15.8262C16.3164 16.2363 16.2754 16.8516 15.8652 17.2207C14.2246 18.5742 12.1328 19.3125 9.95898 19.3125C4.91406 19.3125 0.8125 15.2109 0.8125 10.125C0.8125 5.08008 4.91406 0.978516 9.95898 0.978516C13.2402 0.978516 16.2344 2.74219 17.875 5.44922V1.26562C17.875 0.732422 18.2852 0.28125 18.8594 0.28125C19.3926 0.28125 19.8438 0.732422 19.8438 1.26562Z' fill='white'/%3E%3C/svg%3E");
  }

  .ril__zoomInButton {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.1719 20.9434L16.6758 15.4473C17.8652 14.0117 18.5625 12.166 18.5625 10.1562C18.5625 5.48047 14.707 1.625 10.0312 1.625C5.31445 1.625 1.5 5.48047 1.5 10.1562C1.5 14.873 5.31445 18.6875 10.0312 18.6875C12 18.6875 13.8457 18.0312 15.3223 16.8418L20.8184 22.3379C20.9824 22.543 21.2285 22.625 21.5156 22.625C21.7617 22.625 22.0078 22.543 22.1719 22.3379C22.582 21.9688 22.582 21.3535 22.1719 20.9434ZM10.0312 16.7188C6.38086 16.7188 3.46875 13.8066 3.46875 10.1562C3.46875 6.54688 6.38086 3.59375 10.0312 3.59375C13.6406 3.59375 16.5938 6.54688 16.5938 10.1562C16.5938 13.8066 13.6406 16.7188 10.0312 16.7188ZM13.3125 9.17188H10.9746V6.875C10.9746 6.3418 10.5234 5.89062 10.0312 5.89062C9.49805 5.89062 9.04688 6.3418 9.04688 6.875V9.17188H6.70898C6.17578 9.17188 5.76562 9.62305 5.76562 10.1562C5.76562 10.7305 6.17578 11.1406 6.70898 11.1406H9.04688V13.4375C9.04688 14.0117 9.45703 14.4219 9.99023 14.4219C10.5234 14.4219 10.9336 14.0117 10.9336 13.4375V11.1406H13.2305C13.8457 11.1406 14.2969 10.7305 14.2969 10.1562C14.2969 9.62305 13.8457 9.17188 13.3125 9.17188Z' fill='white'/%3E%3C/svg%3E");
  }

  .ril__zoomOutButton {
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.1719 19.9434L15.6758 14.4473C16.8652 13.0117 17.5625 11.166 17.5625 9.15625C17.5625 4.48047 13.707 0.625 9.03125 0.625C4.31445 0.625 0.5 4.48047 0.5 9.15625C0.5 13.873 4.31445 17.6875 9.03125 17.6875C11 17.6875 12.8457 17.0312 14.3223 15.8418L19.8184 21.3379C19.9824 21.543 20.2285 21.625 20.5156 21.625C20.7617 21.625 21.0078 21.543 21.1719 21.3379C21.582 20.9688 21.582 20.3535 21.1719 19.9434ZM9.03125 15.7188C5.38086 15.7188 2.46875 12.8066 2.46875 9.15625C2.46875 5.54688 5.38086 2.59375 9.03125 2.59375C12.6406 2.59375 15.5938 5.54688 15.5938 9.15625C15.5938 12.8066 12.6406 15.7188 9.03125 15.7188ZM12.3125 8.17188H5.70898C5.17578 8.17188 4.76562 8.62305 4.76562 9.15625C4.76562 9.73047 5.17578 10.1406 5.70898 10.1406H12.2715C12.8457 10.1406 13.2969 9.73047 13.2969 9.15625C13.2969 8.62305 12.8457 8.17188 12.3125 8.17188Z' fill='white'/%3E%3C/svg%3E");
  }

  .ril__closeButton {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.2344 12.0059C13.6035 12.416 13.6035 13.0312 13.2344 13.4004C12.8242 13.8105 12.209 13.8105 11.8398 13.4004L7 8.51953L2.11914 13.4004C1.70898 13.8105 1.09375 13.8105 0.724609 13.4004C0.314453 13.0312 0.314453 12.416 0.724609 12.0059L5.60547 7.125L0.724609 2.24414C0.314453 1.83398 0.314453 1.21875 0.724609 0.849609C1.09375 0.439453 1.70898 0.439453 2.07812 0.849609L7 5.77148L11.8809 0.890625C12.25 0.480469 12.8652 0.480469 13.2344 0.890625C13.6445 1.25977 13.6445 1.875 13.2344 2.28516L8.35352 7.125L13.2344 12.0059Z' fill='white'/%3E%3C/svg%3E");
  }
}

@media (max-width: 320px) {
  .tournament_card {
    .card_img {
      img {
        right: -38px;
      }
    }
  }
}
