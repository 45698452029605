@use 'src/styles/variables';

.confirmation {
  align-items: center;
  color: variables.$color-grey-600;
  display: flex;
  margin-left: auto;
  white-space: nowrap;
}

.icon {
  color: variables.$color-green-500;
  margin-right: variables.$margin-extra-small;
}

.notConfirmed {
  color: variables.$color-grey-600;
  margin-left: auto;
  white-space: nowrap;
}
