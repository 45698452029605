@use '~reset-css/sass/reset';
@use './variables';
@use './font-face';

html,
body,
.root {
  min-height: 100vh;
}

body {
  background-color: variables.$color-white;
  box-sizing: border-box;
  color: variables.$color-black;
  font-family: variables.$font-family;
  font-size: variables.$font-size-common;
  font-weight: normal;
  line-height: #{variables.$font-size-common + 6px};
  margin: 0;
  width: 100%;
}

a {
  color: variables.$color-blue-500;
  text-decoration: none;
}

h1 {
  font-size: variables.$font-size-h1;
  font-weight: 700;
  margin-bottom: 0;
}

.scrollLocked {
  overflow: hidden;
}
